<!-- <div *ngIf="samllPopup">
    <div *ngFor="let search of savedSearches">
        <div (click)="loadSpecificSearch(search)" style="padding: 5px 12px 5px 12px;">{{search.patternName}}</div>
    </div>
    <div (click)="loadAllSearches()" style="display: flex; padding: 5px 12px 5px 12px; justify-content: space-between; border: 1px solid #e5e8e8;">
        <span>See all</span><span>></span>
    </div>

</div> -->


<div *ngIf="samllPopup">
    <div id="popup1" class="overlay" (click)="samllPopup=false"></div>
        <div class="popup-savedsearch">
            <div class="content-smallpopup">
                <div class="title" style="
                /* margin-bottom: 1rem; */
                /* margin-bottom: 1rem; */
                border-bottom: 1px solid #00857c;
                padding: 7px;">Saved Searches</div>
                <div style="    height: 14rem;
                overflow: auto;">

                <div style="height: 13.5rem; overflow: auto;">

                    <div *ngIf="loadingIndicater">
                        Loading...
                    </div>
    
                    <div *ngIf="!noItemFlag">
                        <div *ngFor="let search of savedSearches">
                            <div (click)="loadSpecificSearch(search);customEventsSpecificSearch(search.patternName)" class="small-pop-items">{{search.patternName}}</div>
                        </div>
                    </div>
                    
                </div>

                
                
                <div *ngIf="noItemFlag">
                    <!-- <span>hdsadsa</span> -->
                    <div style="    display: flex;
                    height:200px;
                    justify-content: center;
                    text-align: center;
                    align-items: center; font-family: invention; font-size: 14px;">{{noSavedSearches}}</div>
                </div>
                </div>
                
                <!-- <div (click)="loadAllSearches()" style="display: flex; padding: 5px 12px 5px 12px; justify-content: space-between; border: 1px solid #e5e8e8;">
                    <span style="color: teal; text-decoration: underline;">See all</span>
                </div> -->

                <div>
                    <span style="text-align: left; 
                    /* margin-left: 16px; */
                     justify-content: left;">
                    <div style="display: flex; padding: 5px 12px 5px 12px; justify-content: space-between; border-top: 1px solid #e5e8e8; cursor: pointer;" (click)="loadAllSearches();customEventsSeeAll()">
                        <span style="color: #00857c; text-decoration: underline;">See all</span>
                        <span class="material-icons outlined arrow-left">
                            navigate_next
                            </span>
                    </div>
                </span>
                    <!-- <button class="CancleBtn" (click)="samllPopup=false" >Close</button> -->
                </div>

            </div>
            
        </div>
</div>



<!-- <div class="dropdownContainer" *ngIf="samllPopup">
    <div style="padding:10px">
        <h2 style="border-bottom:1px solid gray;color:black">
            My Saved Searches
        </h2>
    </div>
    <div class="container" *ngFor="let search of savedSearches">
        <img style="cursor: pointer;" (click)="savedSearchesFunction(search,'unsubscribe')" *ngIf="search.subscribed == 1"
            src="./../assets/merck-assets/bell_fill.png" alt="Your Image" class="image">
        <img style="cursor: pointer;" (click)="savedSearchesFunction(search,'subscribe')"
            *ngIf="search.subscribed == 0" src="./../assets/merck-assets/bell_unfill.png" alt="Your Image"
            class="image">
        <p style="color: black;" class="text">{{search.patternName}}</p>
        <span (click)="savedSearchesFunction(search,'rename')" class="colSpace">Rename</span>
        <span (click)="savedSearchesFunction(search,'delete')" class="colSpace">Delete</span>
        <span (click)="savedSearchesFunction(search,'share')" class="colSpace">Share</span>
    </div>
    <div (click)="loadAllSearches()">
        Load all...
    </div>

</div> -->

<div *ngIf="SavedSearchAddPopupShow">
    <div id="popup1" class="overlay" (click)="closePopup()"></div>
        <div class="popup">
            <div class="content">
                <div class="title" style="">{{title}}</div>
                <div style="display: flex; flex-direction: column;">
                    <div class="input-section">
                        <div style="margin-bottom: 4px;
                        font-size: 14px;
                        font-weight: 400;">Name <b style="color:red">*</b></div><div>
                            <input class="inputbox" (keyup)="keyDownValidator($event);" (keydown)="keyDownValidator($event);" style="width: 440px;" type="text" maxlength="50" [(ngModel)]="finalBindSearchTerm"/>
                        </div>
                        <div style="color: red; font-weight: 400; font-size: 14px;">{{SavedSearchTextMsg}}</div>
                        <!-- <div style="color: red;">{{popupMsg}}</div> -->
                    </div>
                    <div class="input-section">
                        <div style="margin-bottom: 4px;
                        font-size: 14px;
                        font-weight: 400;">Description</div><div><textarea  style="width: 460px; height: 50px; resize: none;  border: 1px solid rgba(0, 0, 0, 0.3);"  (input)="onInputChange($event)" maxlength={{maxLength}}  [(ngModel)]="description"></textarea>
                         <p [style.color]="color">{{descriptionValidationMessage}}</p>    
                    </div>
                       
                        <!-- <div style="color: red;">{{popupMsg}}</div> -->
                    </div>
                    <div class="btn-section">
<!--                         <button class="CancleBtn" (click)="closePopup()" >Close</button><button class="teal-button" (click)="savePattern(searchPatternName)" formtarget="_blank" >Save</button> -->
                        <button class="CancleBtn" (click)="closePopup()" >Close</button><button class="teal-button" id="savePatternId" (click)="savePattern(finalBindSearchTerm,description);customEventsSaveSearch(finalBindSearchTerm)" formtarget="_blank" [disabled]="!finalBindSearchTerm">Save</button>
                    </div>
                </div>
            </div>
        </div>
</div>





<!-- 
<div *ngIf="SavedSearchRenamePopupShow">
    <div id="popup1" class="overlay" (click)="closeRenamePopup()"></div>
        <div class="popup">
            <div class="content">
                <div class="title">Rename Saved search</div>
                <div style="display: flex; flex-direction: column;">
                    <div class="input-section">
                        <div>Name</div><div><input (input)=getRename($event) class="inputbox" type="text" [value]="selectedSearch.patternName"/></div>
                        <div style="font-weight: 400; font-size: 12px; color: red;">{{message}}</div>
                    </div>
                    <div class="btn-section">
                       
                        <button   class="CancleBtn" (click)="SavedSearchRenamePopupShow = false;flag = ''" >Cancel</button>
                        <button (click)="renamePattern(searchPatternName)" id="renamePatternId" class="docFavSaveBtn" [disabled]="renameBtnDisable">Rename</button>
                    </div>
                </div>
            </div>
        </div>
</div> -->


<div *ngIf="SavedSearchRenamePopupShow">
    <div id="popup1" class="overlay" (click)="closeRenamePopup()"></div>
        <div class="popup">
            <div class="content">
                <div class="title">Edit Saved search</div>
                <div style="display: flex; flex-direction: column;">
                    <div class="input-section">
                        <div>Name <b style="color:red">*</b></div><div>
                            <input id ="saveRename" (keyup)=getRename($event) (keydown)=getRename($event) (input)=getRename($event) style="width: 440px;" maxlength="50" class="inputbox" type="text" [value]="selectedSearch.patternName"/>
                            <!-- <input type="text" id ="saveRename" (keyup)="getRename($event)" style="width: 440px;" maxlength="50" class="inputbox" [value]="getValue(selectedSearch.patternName)"/> -->
                        </div>
                        <div style="font-weight: 400; font-size: 12px; color: red;">{{message}}</div>
                    </div>
                    <div class="input-section">
                        <div style="margin-bottom: 4px;
                        font-size: 14px;
                        font-weight: 400;">Description</div><div><textarea  (input)="getUpdatedDescription($event);" maxlength="100" style="width: 460px; height: 50px; resize: none;  border: 1px solid rgba(0, 0, 0, 0.3);"  [value]="selectedSearch.description"></textarea> <p [style.color]="color">{{descriptionValidationMessage}}</p></div>
                       
                        <!-- <div style="color: red;">{{popupMsg}}</div> -->
                    </div>
                    <div class="btn-section">
                        <!-- <button class="CancleBtn" (click)="closePopup()" >Close</button><button class="teal-button" (click)="savePattern(searchPatternName)" formtarget="_blank">Save</button> -->
                        <button   class="CancleBtn" (click)="SavedSearchRenamePopupShow = false;flag = ''; descriptionValidationMessage=''; " >Cancel</button>
                        <button (click)="renamePattern(selectedSearch.patternName)" id="renamePatternId" class="docFavSaveBtn" [disabled]="renameBtnDisable">Save</button>
                    </div>
                </div>
            </div>
        </div>
</div>

<div *ngIf="SavedSearchSubscribePopupShow">
    <div id="popup1" class="overlay" (click)="closeRenamePopup()"></div>
    <div class="popup">
        <!-- <a class="close" href="javascript:void(0);" (click)="SavedSearchSubscribePopupShow = false;flag = ''">&times;</a> -->
        <div class="content">
            <div class="title">Disable Notifications</div>
            <div style="display: flex; flex-direction: column;">
                <div class="input-section">
                    {{disableNotificationsText}} "{{selectedSearch.patternName}}" ?
                </div>
                <div class="btn-section">
                    <button   class="docFavCancleBtn" (click)="SavedSearchSubscribePopupShow = false;flag = ''" >No</button>
                    <button id="subscriptionId" (click)="subscription()" class="docFavSaveBtn" >Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="SavedSearchDeletePopupShow">
    <div id="popup1" class="overlay" (click)="SavedSearchDeletePopupShow = false;flag = ''"></div>
    <div class="popup">
        <div class="content">
            <div class="title">Delete Confirmation</div>
            <div style="display: flex; flex-direction: column;">
                <div class="input-section">
                     <span *ngIf="this.selectionList.length  == 0">Do you want to <span style="font-weight: bold;">delete your saved search</span> named, "{{selectedSearch.patternName}}" ?</span>
                     <span *ngIf="this.selectionList.length  > 0">Do you want to <span style="font-weight: bold;">delete your saved searches</span> named, "{{deleteSavedSearchNames}}" ?</span>
                </div>
                <div class="btn-section">
                    <!-- <button class="CancleBtn" (click)="closePopup()" >Close</button><button class="teal-button" (click)="savePattern(searchPatternName)" formtarget="_blank">Save</button> -->
                    <button class="docFavCancleBtn" (click)="SavedSearchDeletePopupShow = false;this.deleteSavedSearchNames = ''" >No</button>
                <button  *ngIf="this.selectionList.length  == 0" id="deleteSearchId" (click)="deleteSearch()" class="docFavSaveBtn" >Yes</button>
                <button *ngIf="this.selectionList.length  > 0" id="deleteSearchId" (click)="deleteMultiSearch()" class="docFavSaveBtn" >Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="bigListPopup">
    <div style="margin-bottom: 2rem;">
        <span class="headingMain">My Saved Searches</span>
    </div>
    <div class="subHeadingsMain" style="display: flex;border-bottom:1px solid #E2E2E2;margin-bottom:1rem; gap:10px;">
        <!-- <div style="flex-basis: 30%; "><input id="mainCheckBox" style="accent-color: teal; display: none;" (click)="mainCheckBox()" type="checkbox" ></div> -->
        <div style="flex-basis: 5%;"></div>
        <div style="flex-basis: 30%;">Saved Search Name</div>
        <div style="flex-basis: 50%;">Description</div>
        <!-- <div style="flex-basis: 25%;" >Last Modified</div> -->
        <div style="flex-basis: 20%;">Last Modified Date</div>
        <div style="flex-basis: 20%;">Notifications</div>
        <div style="flex-basis: 30%;"><span>Actions</span></div>
    </div>
    <div>
        <div *ngIf="loadingIndicater">
            Loading...
        </div>
        <ng-container *ngIf="!noItemAllSavedFlag">
            <div *ngFor="let search of allSavedSearches">
                <div>
                    <div style="display: flex; font-size: 14px;
                    font-weight: 400; margin-bottom: 1rem;justify-content: center;
        align-items: center; gap:10px;">

<div  style="flex-basis: 5%;">
    <span style="color:teal; font-size: 14px;
    font-weight: 400; cursor: pointer;"> <input style="accent-color: teal;" (click)="addRemoveToMultiList(search)" id="{{search.searchpatternId}}"  class="multiselect" type="checkbox"> </span>

    
</div>
                        <!-- <div  style="flex-basis: 30%;" (click)="loadSpecificSearchInResults(search)"> -->
                           
                        <div  style="flex-basis: 30%;" (click)="loadSpecificSearchInResults(search);customEventSavedSearchSpecific(search)">
                             <span style="color:teal; font-size: 14px;
                            font-weight: 400; cursor: pointer;">  {{search.patternName}}</span>

                            
                        </div>
                        <div  style="flex-basis: 50%;">
                            <span style="color:teal; font-size: 14px;
                            font-weight: 400; word-break: break-all;"> {{search.description}}</span>
                        </div>


                        <div class="bigListSavedSearchDate" style="flex-basis: 20%;">

                            <span  style="color:teal; font-size: 14px;
                            font-weight: 400; cursor: pointer;">  {{getDateFormated(search.lastupdateddate) | date}}</span>

                        </div>
                       

                        <!-- <div style="flex-basis: 20%;">
                            <img style="cursor: pointer; margin-left: 2rem;" (click)="savedSearchesFunction(search,'unsubscribe')" -->
                        <div class="bigListSavedSearchDate" style="flex-basis: 20%;">
                            <img style="cursor: pointer; margin-left: 2rem;" (click)="savedSearchesFunction(search,'unsubscribe'); customEventSavedSearchUnsubscribe(search)"
                                *ngIf="search.subscribed == 1 &&  this.selectionList.length == 0" src="./../assets/merck-assets/bell_fill.png" alt="Your Image" title="Disable Notifications"
                                class="image">
                            <img style="cursor: pointer; margin-left: 2rem;" (click)="savedSearchesFunction(search,'subscribe'); customEventSavedSearchsubscribe(search)"
                                *ngIf="search.subscribed == 0 && this.selectionList.length == 0" src="./../assets/merck-assets/bell_unfill.png" alt="Your Image"  title="Enable Notifications"
                                class="image">
                                <img  style="cursor: not-allowed; margin-left: 2rem;" 
                                *ngIf="this.selectionList.length > 0" src="./../assets/merck-assets/bell_disable.png" alt="Your Image" 
                                class="image">
                        </div>
    
                        
                        <div class="bigListSavedSearchaction" style="flex-basis: 30%;display: flex;
                        justify-content: space-between;">
                            <span *ngIf="this.selectionList.length == 0" (click)="savedSearchesFunction(search,'rename');customEventSavedSearchRename(search)" class="colSpace">Edit</span>
                            <span  *ngIf="this.selectionList.length > 0" style="color:darkgray; cursor: not-allowed;">Edit</span>
                            <span (click)="savedSearchesFunction(search,'share');customEventSavedSearchShare(search)" class="colSpace">Share</span>
                            <span style="color: red;" (click)="savedSearchesFunction(search,'delete');customEventSavedSearchDelete(search)"
                                class="colSpace">Delete</span>
        
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- <ng-container *ngIf="allSavedSearches.length == 0">
            <span>{{{{noRecordsMsg}}}}</span>
        </ng-container> -->
        <ng-container *ngIf="noItemAllSavedFlag">
            <div style="    display: flex;
            height: 400px;
            justify-content: center;
            text-align: center;
            align-items: center; font-family: invention; font-size: 14px;">{{noSavedSearches}}</div>
          </ng-container>
   
    </div>

</div>

<div *ngIf="SavedSearchMsg">
    <div id="popup1" class="overlay" (click)="closePopup()">
        <div class="popup">
            <!-- <h2>{{title}}</h2> -->
            <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
            <div class="content">
                <div class="title">Saved Search</div>
                <div style="display: flex; flex-direction: column;">
                    <div class="input-section">
                        {{message}}
                    </div>
                    <div class="btn-section">
                        <!-- <button (click)="SavedSearchMsg=false" class="docFavCancleBtn" >Close</button> -->
                        <button (click)="closePopup(); SavedSearchMsg=false;" class="docFavCancleBtn" >Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="maxSelectionReached">
    <div id="popup1" class="overlay" (click)="maxSelectionReached = false">
        <div class="popup">
            <!-- <h2>{{title}}</h2> -->
            <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
            <div class="content">
                <div class="title">You may only select up to 10 at once.</div>
                <div style="display: flex; flex-direction: column;">
                    <div class="input-section">
                        Maximum number of selections reached
                    </div>
                    <div class="btn-section">
                        <!-- <button (click)="SavedSearchMsg=false" class="docFavCancleBtn" >Close</button> -->
                        <button (click)=" maxSelectionReached = false;" class="docFavCancleBtn" >Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>