    <div id="viewer">
        <!-- <embed frameborder="0" 
        marginheight="0" 
        marginwidth="0" 
        width="100%" 
        height="100%" 
        scrolling="auto" class="responsive-frame" [src]="safeUrl"  type="text/html"/> -->


        <iframe style="height:94vh; width:100%" height="100%" width="100%" class="responsive-iframe" [src]="safeUrl" type="text/html"></iframe>

    </div>
    <div [style.width]="overlapsize+'rem'" style="position: absolute;
    top: 2px;
    background: white;
    height: 37px;"></div>
    <!-- <iframe
      class="responsive-iframe"
      src="https://sb-merck-medcomm-uat.veevavault.com/ui/approved_viewer?api=true&amp;token=82813-c548da80-4497-4528-9740-d30b35e045c5&amp;email=undefined&amp;orgid=undefined&amp;canDownload=false"
      type="text/html"
    ></iframe> -->


    

    

    <!-- <script>
        var tag = document.createElement('script');
        tag.src = "vault.js";
        var firstScriptTag = document.getElementsByTagName('script')?.[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        var viewer;

        function onVeevaVaultIframeAPIReady() {
            viewer = new VV.Viewer('viewer', {
                height: '600',
                width: '1200',
                error: 'We\'re sorry. The document you are trying to view is not available.'
            });

        }
    </script> -->