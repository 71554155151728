<!-- <div class="container" style="padding-bottom: 40px;padding-top:40px">
    <div class="title">
        <h1>Recomendation</h1>
    </div>
    <div class="card-container">
        <div *ngFor="let item of CardsResponse;" class="card">
            <div class="card-content">
                <h2>{{item.title}}</h2>
                <p>{{item.subTitle}}</p>
                <div style="display: flex;justify-content:space-between">
                    <span>{{item.source}} 
                    </span>
                    <img style="width: 20px;margin:-5px 0px 0px 35px" src="../../assets/images/source_image.png" alt="source image">
                    <span>
                        PDF
                    </span>
                </div>
            </div>
        </div>
       
    </div>
</div> -->

<div class="container" style="padding-bottom: 40px; padding-top:40px">
    <div class="title">
        <span class="title">Personal Collections</span>
        <span (click)="navigateToSettings()" class="seeAllButtons">See All</span>
    </div>
    <div class="card-container">
        <ng-container *ngIf="limitedCollections && limitedCollections.length > 0; else noCollections">
            <div class="collectionsContainer">
                <div style="cursor: pointer;width: 225px !important;" *ngFor="let item of limitedCollections.slice(0, 5)" class="collectionsName">
                  <span (click)="loadSpecificCollection(item,'')" [title]="item.name">{{ item.name.length > 25 ? (item.name | slice:0:25) + '...' : item.name }} ({{ item.items.length }})</span>
                </div>
              </div>              
        </ng-container>
        
        <ng-template #noCollections>
            <div class="no-collections-message" style="text-align: left;padding:5px">
                No collections available.
            </div>
        </ng-template>
    </div>
</div>


