import {AfterViewInit, Component, OnInit} from '@angular/core';
import { EngineService } from './engine.service';
import { CookieServiceWrapper } from './cookie-service-wrapper.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'angular';

 ngOnInit(): void {  
  console.log("%cSTOP", "font-size: 24px; color: red; font-weight: bold;", "This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a V&I Search Navigator feature or \"hack\" someone's account, it is a scam and will give them access to your Content Search Navigator account.");
 }

}