

export const ENVIRONMENT = {
    "searchsettings" : true,
    privatekey : 'salkdjlkdsasanci',
    DOMAIN:{
       HOST:"gmsasearch-sit.merck.com"
       // HOST:"localhost"
    },

NOTIFICATIONS: {
   GET_ALL_NOTIFICATIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/getallnotifications",
   REMOVE_NOTIFICATIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/removenotifications"
},
ONEDRIVE: {
   REGISTER_USER: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsaGetAccessTokenFromOD",
   ADD_TO_ONEDRIVE_VVMC: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/addtoonedrive/vvmc",
   ADD_TO_ONEDRIVE_VVQD: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/addtoonedrive/vvqd",
   ONEDIVE_DELETE_URL: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsaDeleteDocumentFromOneDrive",
   ONEDRIVE_GETALLDOCS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsacheckthedocumentinod",
   CHECK_IF_USER_REGISTERED: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsaGetAccessTokenFromOD",
   START_MANUAL_SYNC: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/manualsync",
   PROGRESS_BAR: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/checkmanualsyncprogress",
},
AUTH: {
   AUTH_SERVICE_URL: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gettoken",
   AUTH_GET_USER_DB_SERVICE_URL: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/getUserDetailsFromDB",
   AUTH_USER_COUNTRY: "https://iapi.merck.com/internal-directory/v2/users/",
   AUTH_USER_LAST_LOGGED_IN: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsa-last-logged-in-date-time",
   GET_USER_COUNTRY_MAP: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/getCountryMap",
   XMERCKAPIKEY: "vb75zNWFdbI9zUEj2yv6RgkhjFh5lsxC"
},
COLLECTIONS: {
   GET_ALL_COLLECTIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/getallcollections",
   ADD_TO_COLLECTIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/rename-deletecollection",
   CREATE_COLLECTIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/createcollection",
   RENAME_DELETE_COLLECTIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/rename-deletecollection"
},
SAVEDSEARCH: {

   GET_ALL_PATTERNS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/getallpatterns",
   SAVE_SEARCH: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/coveosrchsubscription",
   RENAME_DELETE_PATTERN: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/renamedeletepatternname",
   ADD_TO_COLLECTIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/rename-deletecollection",
   CREATE_COLLECTIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/createcollection",
   SUBSCRIBE_COLLECTIONS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsaUnsubscribeSavedSearch"

},
VIEWPAGE: {
   TOKEN_URL: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/getvvmcdoctoken",
   DWONLOAD_URL: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/veevafile/file"
},
SETTINGS: {
   GET_ALL_SETTINGS_DATA: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsa-user-settings-getdata",
   UPDATE_USER_SETTINGS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsa-user-settings",
   GET_USER_SETTINGS: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/get-user-settings-by-userid",
   UPDATE_COLLECTION_USED: "https://iapi-qa.merck.com/gmsa-search-navigator/v1/gmsa-collections-used-update"
}
}
 
 export var CONFIG = {
 
    LANDING: {
                   //   URL: "http://localhost:4200/landing"    
                     URL:"https://gmsasearch-sit.merck.com/landing",
                     AUTH: "https://gmsasearch-sit.merck.com/auth"

    },
    CONFIRMATION: {
       REDIRECT_URL: "https://gmsasearch-sit.merck.com", 
  },
  VIEWPAGE: {
    URL:"//sb-msd-medcomm-uat.veevavault.com/ui/approved_viewer?api=true"
  },
 
 
   ONEDRIVE : {
              SINGLE_DOCUMENT_DOWNLOAD_FAILURE_SPCL: 'The document failed to download to OneDrive due to special characters in the file name.',
           SINGLE_DOCUMENT_DOWNLOAD_FAILURE_BIGSIZE: 'The document failed to download to OneDrive due to bigger file size.',
           TENANT_ID:"a00de4ec-48a8-43a6-be74-e31274e2060d",
           CLIENT_ID:'944e5d05-6d1f-4b5c-ab42-320865a6f82f',
            //REDIRECT_URL:'http://localhost:4200/odregister',
           //REDIRECT_URL:'https://gmsasearch-sit.merck.com',
            REDIRECT_URL:"https://gmsasearch-sit.merck.com/odregister",
           REDIRECT_URL_MULTIPLE:"https://gmsasearch-sit.merck.com/odregister_multiple",
           //REDIRECT_URL_MULTIPLE:'http://localhost:4200/odregister_multiple',
          REGISTRATION_TITLE: "Would you like to grant permission to register for OneDrive?", 
        REGISTRATION_PROGRESS_MESSAGE: "The system is granting permission to download document to your OneDrive...",
        REGISTRATION_PROGRESS_MULTI_MESSAGE: "The system is granting permission to download document(s) to your OneDrive...",
        REGISTRATION_SUCCESS_MESSAGE:"Successfully registered to OneDrive. The selected document has been successfully downloaded. Please close this tab, and refresh the Search Navigator window", 
        REGISTRATION_SUCCESS_MULTI_MESSAGE:"Successfully registered to OneDrive. The selected document(s) has been successfully downloaded. Please close this tab, and refresh the Search Navigator window", 
        REGISTRATION_SUCCESS__PARTIAL_MESSAGE_1:"Successfully registered to OneDrive. The following ", 
        REGISTRATION_SUCCESS__PARTIAL_MESSAGE_2:" document(s) failed to download to OneDrive due to technical reasons. Please try after sometime, close this tab and refresh the Search Navigator window.", 
        REGISTRATION_FAILURE_MESSAGE: "Something went wrong! Please try again.",
        CONTACT_MESSAGE: "* If the problem still persists, please contact help desk.",
        REGISTRATION_FAILURE_MULTI_MESSAGE: "You are successfully registered to OneDrive. The selected document(s) failed to download to OneDrive. Please close this tab, and refresh the Search Navigator window", 
        REMOVE_SINGLE_DOCUMENT_BODY:"Do you want to remove the document from OneDrive?",
        REMOVE_SINGLE_DOCUMENT_TITLE:"Delete from OneDrive",
        REMOVE_SINGLE_DOCUMENT_SUCCESS: "Document Deleted Successfully from OneDrive",
        REMOVE_SINGLE_DOCUMENT_FAILURE: "Document deletion failed due to technical failure",
        DOWNLOAD_SINGLE_DOCUMENT_TITLE:"Download documents to OneDrive",
        DOWNLOAD_MULTIPLE_DOCUMENT_TITLE:"Download selected documents to OneDrive",
        DOWNLOAD_SINGLE_DOCUMENT_BODY:"Would you like to download this document to your OneDrive?", 
        DOWNLOAD_MULTIPLE_DOCUMENT_BODY:"Would you like to download selected documents to your OneDrive?", 
        INFO_MSG: `Note: Once download, do not rename, move, or delete the documents within the "Content Synced from Search Navigator" folder or the folder itself on your OneDrive. These actions could prevent documents from syncing and result in outdated content on your OneDrive.`,
        DOWNLOAD_SINGLE_DOCUMENT_SUCCESS: "Selected document downloaded successfully!",
        DOWNLOAD_MULTIPLE_DOCUMENT_SUCCESS: "Selected document downloaded successfully!",
        DOWNLOAD_SINGLE_DOCUMENT_FAILURE: "Selected document failed!",
        DOWNLOAD_MULTIPLE_DOCUMENT_FAILURE: "Document(s) download failed due to technical failure",
        REMOVE_SINGLE_DOCUMENT_INPROGRESS: "* Document deletion continues in background, please feel free to close this popup.",
        DOWNLOAD_SINGLE_DOCUMENT_INPROGRESS: "* Document download continues in background, please feel free to close this popup.",
        UNFILLED_ICON:"../../assets/merck-assets/OneDrive_unfilled.jpeg",
        FILLED_ICON:"../../assets/merck-assets/OneDrive_filled.jpeg",
        INFO_MESSAGE:`Note: Once download, do not rename, move, or delete the documents within the "Content Synced from Search Navigator" folder or the folder itself on your OneDrive. These actions could prevent documents from syncing and result in outdated content on your OneDrive.`,
        REMOVE_MULTI_DOCUMENT_BODY:"Do you want to remove the selected document(s) from OneDrive?",
        REMOVE_MULTI_DOCUMENT_TITLE:"Delete from OneDrive",
        REMOVE_MULTI_DOCUMENT_SUCCESS: "Document(s) Deleted Successfully from OneDrive",
        REMOVE_MULTI_DOCUMENT_FAILURE: "Documents deletion failed due to technical failure", 
        REMOVE_MULTI_DOCUMENT_INPROGRESS: "* Document(s) deletion continues in background, please feel free to close this popup.",     
        ADD_MULTI_DOCUMENT_INPROGRESS: "* Document(s) download continues in background, please feel free to close this popup.",    
        SINGLE_DOCUMENT_DOWNLOAD_USERTEXT: "Would you like to download this document to your OneDrive?",
        ONEDRIVE_DOWNLOAD_TITLE: "Download to OneDrive",
        SINGLE_DOCUMENT_DOWNLOAD_PROGRESS_MESSAGE: "Document download continues in background, please feel free to close this popup.",
        SINGLE_DOCUMENT_DOWNLOAD_SUCCESS: "Selected document downloaded successfully.",
        SINGLE_DOCUMENT_DOWNLOAD_FAILURE: 'The document failed to download to OneDrive due to some technical reasons .Please try after sometime.',
        MULTI_DOCUMENT_DOWNLOAD_USERTEXT: "Would you like to download the selected document(s) to your OneDrive?",
        MULTI_DOCUMENT_DOWNLOAD_PROGRESS_MESSAGE: "Document(s) download continues in background, please feel free to close this popup.",
        MULTI_DOCUMENT_DOWNLOAD_SUCCESS: "The selected document(s) downloaded successfully to OneDrive.",
        MULTI_DOCUMENT_DOWNLOAD_FAILURE_PART1: `The following  `,
        MULTI_DOCUMENT_DOWNLOAD_FAILURE_PART2:" document(s) failed to download to OneDrive due to technical reasons.Please try again after sometime.",
        SINGLE_DOCUMENT_REMOVE_USERTEXT: "Would you like to remove this document from your OneDrive?",
        ONEDRIVE_REMOVE_TITLE: "Remove from OneDrive",
        SINGLE_DOCUMENT_REMOVE_PROGRESS_MESSAGE: "Document removal continues in background, please feel free to close this popup",
        SINGLE_DOCUMENT_REMOVE_SUCCESS: "Document removed from OneDrive.",
       // SINGLE_DOCUMENT_REMOVE_FAILURE: 'Download is failed.',
        MULTI_DOCUMENT_REMOVE_USERTEXT: "Would you like to remove the selected document(s) from your OneDrive?",
        MULTI_DOCUMENT_REMOVE_PROGRESS_MESSAGE: "Document(s) removal continues in background, please feel free to close this popup",
        MULTI_DOCUMENT_REMOVE_SUCCESS: "Selected document(s) removed successfully from OneDrive.",
      },
   MULTI_SELECT: {
        SELECTION_FREEZE_TITLE: "Selection Freezed", 
        SELECTION_FREEZE_BODY:  "The selection list cannot be changed once the operation is in progress."  , 
        SELECTION_EXCEED_TITLE: "You may only select up to 10 at once.",
        SELECTION_EXCEED_BODY: "Maximum number of selections reached",
        OPERATION_IN_PROGRESS: "Download / Removal of document(s) to One Drive in progress, please wait before you select more."
   },
 NOTIFICATIONS: {
   CLEARALLNOTIFICATIONS_TEXT: "Do you want to clear all Notifications?", 
   CLEARALLNOTIFICATIONS_SUCCESS_TEXT: "All the notifications are cleared"
 
 } ,
 COLLECTIONS : {
    EMAIL_SHARE_LINK: "https://gmsasearch-sit.merck.com/share/collections/",
   FILLED_ICON : "../../assets/merck-assets/bookmark_filled.png",
   UNFILLED_ICON : "../../assets/merck-assets/bookmark_unfilled.png",
   NO_COLLECTIONS_AVAILABLE: "No collections available.",
   ADD_REMOVE_COLLECTIONS_TITLE:"Add/Remove from Collection(s)", 
   ADD_REMOVE_COLLECTIONS_INFO_MESSAGE1: "To add to a Collection check the box.", 
   ADD_REMOVE_COLLECTIONS_INFO_MESSAGE2: "To remove from a Collection uncheck the box.", 
   SUCCESSFUL_MESSAGE: "Successfully added or removed document(s)/link(s) to the Collections.",
   NO_RECORDS_AVAILABLE: "No records are available.", 
   SHARE_LINK_URL: "https://gmsasearch-sit.merck.com/index.html", 
   COLLECTION_WITH_SAME_NAME: "A collection with that name already exists. Please enter a different name.",
   SUCCESSFUL_RENAME_COLLECTION:"Successfully renamed the Collection.", 
   SUCCESSFUL_DELETE_COLLECTION:"Successfully deleted the Collection."
 },
 SAVED_SEARCHES:{
    EMAIL_SHARE_LINK:"https://gmsasearch-sit.merck.com/share/searches/",
     MAX_LENGTH:100,
    DESCRIPTION_TEXT_PLACEHOLDER:"max number of characters: 100 " ,
     DISABLE_NOTIFICATIONS_TEXT: "Do you want to stop receiving notifications for your saved search named", 
     NO_SAVED_SEARCHES: "No saved search available", 
     DUPLICATE_SAVED_SEARCH: "A saved search with that name already exists. Please enter a different name.",
     SAVED_SEARCH_SUCCESS: "Your Search has been successfully saved. Notifications will be received when new or updated news links or documents meet the saved search criteria.", 
     SAVED_SEARCH_RENAME_SUCCESS: "This search is renamed sucessfully.",
     SHARE_LINK: "https://gmsasearch-sit.merck.com/index.html", 
     SHARE_EMAIL_SUBJECT: "V&I Search Navigator: Asset share", 
     SHARED_LINK_EMAIL: "https://gmsasearch-sit.merck.com/share/searches/", 
     DISCLAIMER_LABEL:"About V&I Search Navigator:", 
     DISCLAIMER_TEXT:"The V&I Search Navigator is an advanced search engine tool that provides GMSA colleagues with a simplified approach for efficiently and effectively searching across multiple repositories for scientific and related content.",
 },
 AUTHENTICATION:{
              NOT_ADMIN: "You do not have access to the Admin Functionality.",
        USER_DEPROVISIONED:"Your access to Search Navigator has been removed due to your recent organization move. If this is an error, please submit a help desk ticket to restore access.",
        ACCESS_RESTRICTION: "You do not have access to the Search Navigator.", 
        ACTIVE_DIRECTORY_URL: "https://gmsasrch-sit.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=71eooru3q0dut7pgjoki9oq79p&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Fgmsasearch-sit.merck.com%2Fauth", 
        GET_USER_COUNTRY: "https://iapi.merck.com/internal-directory/v2/users/"
 },
 CSNSHARE: {
        COLLECTIONS_TITLE: "My Collections", 
        COLLECTIONS_BODY: "This collection has been deleted by its owner and is no longer available.", 
        SAVED_SEARCH_TITLE: "My Saved Search", 
        SAVED_SEARCH_BODY:  "This Saved Search has been deleted by its owner and is no longer available."
 },
 RESULTS_PAGE: {
 SAVESEARCH_POPUP_MESSAGE:  "Your Search has been successfully saved. Notifications will be received when new or updated news links or documents meet the saved search criteria.", 
  NO_DOCUMENTS_AVAILABLE: "No documents available in this collection.", 
  MY_ONEDRIVE_NO_DOCUMENTS: "There is no content downloaded from Search Navigator to your OneDrive."
 }, 
   TEMP: {
           USERID:2,
           DOCID:4298,
           SOURCE: "VVMC"
   }, 
   EXPORT_TO_EXCEL: {
    API_URL: "https://merckholdingsllcnonproduction2x7d3y0p7.org.coveo.com/rest/search/v2?organizationId=merckholdingsllcnonproduction2x7d3y0p7",
    SELECT_ATLEAST_ONE_COLUMN:"Please select at least one column to export", 
    ONLY_FIRST_5000_COLUMNS: "Only the first 5000 records will be exported",
    EXPORT_SUCCESS: "Selected columns exported to excel"
   }, 
    USER_DEPROVISIONING: {
         API_URL: "https://txxm2jra6e.execute-api.us-east-1.amazonaws.com/sit/gmsa-search-refactored-getDeprovisionedUsers", 
         REDIRECT_URL: "/userdep",
         LOADING_MESSAGE: "Please wait while the data is loading...", 
         SOMETHING_WENT_WRONG:'Something went wrong! Please try again after some time', 
         NO_DATA_aVAILABLE: 'No Data Available', 
         START_DATE: 'Start date is required. Please select start date', 
         END_DATE:'End date cannot be less than start date', 
         NO_DATA_AVAILABLE_FOR_SELECTION: 'No data available for the selected period'
    }
 }
 
 export const NON_PROXY_API = [
   "org.coveo.com",
   "internal-directory"
]

export const COLUMN_MAPPING = {
   "title": "Document Name / Title",
   "contentintendeduse": "Intended Use",
   "description": "Description",
   "source": "Source",
   "contentdocumentnumber": "Document Number",
   "contentdocumenttype": "Document Type",
   "vvpmcontentdocumentnumber": "Document Number",
   "vvpmcontentdocumenttype": "Document Type",
   "gmsacountry": "Country",
   "vvpmcountry": "Country",
   "contentinclm": "Content In CRM",
   "contentinirms": "Content In MI",
   "Uri": "Source Link",
   "contentproductname": "Product",
   "vvmctherapeuticarea": "Therapeutic Area",
   "language": "Language",
   "contentproactive": "Proactive",
   "docOwner": "Document Owner",
   "gmsaapprovaldate": "Approval Date",
   "contentsourceid": "Document ID",
   "contentitemstatus": "Status",
   "vvmctherapeuticareaveap": "Therapeutic Area (VEAP)",
   "vvmcgvdveapid": "REV/OPS ID",
   "vvmcgvdkeycontact": "Key Contact",
   "vvmcgvddepartment": "Department (VEAP)",
   "vvmc_core_model_type": "Model Type",
   "vvmc_core_dcr_approval_date": "DRC Approval Date",
   "vvmc_core_activity_type": "Activity Type",
   "vvmcgvdeffectivedate": "Effective Date",
   "payer": 'Payor Use Only',
   "payerregion": 'Payor Region',
   "payertopic": 'Payor Topic',
   "gmsa_sub_therapeutic_area": "Sub Therapeutic Area",
   "niscompletiondate": "Planned/Actual Report Completion Date",
   "nisapprovaldate": "Planned/Actual Protocol Approval Date",
   "nisactivitystatus": "Activity Status (REV/OPS)",
   "veapactivitytype": "Activity type (REV/OPS)",
   "veapcountry": "Country (REV/OPS)",
   "veapcustomerbusline": "Customer Business Line",
   "veapisldg": "Is LDG? (REV/OPS)",
   "veapmkvnumber": "MK/V Number",
   "veapproduct": "Name of Product (REV/OPS)",
   "veapcontact": "Steward/Contact (REV/OPS)",
   "veap_obj_id": "REV/OPS ID",
   "veapstudyobjective": "Study Objective",
   "veaptherapeuticarea": "Therapeutic Area (REV/OPS)",
   "veapindication": "Indication/Disease (REV/OPS)",
   "veaptitle": "Title",
   //   "title" : "Document Name",
   "uri": "Source Link",
   "syssource": "Source",
   "qddocumenttype": "Document Type",
   "qddocumentsubtype": "Document Sub Type",
   "contentdocumentsubtype": "Document Sub Type",
   //  "contentdocumentnumber":"Document Number",
   //  "contentitemstatus":"Status",
   "mrlclassification": "MRL Classification",
   "qdsensitivityclassification": "Sensitivity Classification",
   "impactedcountry": "Impacted Countries/Regions",
   "contentversion": "Version",
   "qdimpacteddepartment": "Impacted Department",
   "qdeffectivedate": "Effective Date",
   "qdfinaldate": "Final Date",
   //  "contentdocumentnumber":"Document Number",
   //  "title" : "Document Name",
   "author": "Author",
   "printableUri": "Source Link",
   "contenttherapeuticarea": "Therapeutic Area",
   "contentspcategoryannouncements": "Announcements",
   "contentspcategoryfunctionalareas": "Functional Areas",
   "contentspcategorygmacapabilities": "GMA Capabilities",
   "contentspcategoryleadermessages": "Leader Messages",
   "gmsapublisheddate": "Published Date",
   "gmsaregion": "Region",
   "contentspcategoryspecialtopics": "Special Topics",
   "contentdocumentowner": "Document Owner",
   "qddocumentversionowner": "Version Owner",
   "submitter": "Submitter",
   "region": "Region",
   "category": "Category",
   "event": "Event",
   "submission_date": "Submission Date",
   "therapeutic_area": "Therapeutic Area(s)",
   "gmsavvpmclassification": "Classification",
   "gmsavvpmresourcetype": "Resource Type",
   "approvaldatedistribution": "Approval for Distribution Date",
   "steward": "Steward/Contact",
   "gmsavvpmfranchise": "Therapeutic Area",
   "pie_disease_type": "Tumor or Disease",
   "pie_type_patient_engagement": "Type of Engagement",
   "pie_author": "Steward",
   "pie_region": "Region",
   "pie_date": "Year",
   "pie_therapeutic_area": "Therapeutic Area",
   "pie_clinicaltrial": "Clinical Trials",
   "pie_access": "Access",
   "pie_patientexperience": "Patient Experience",
   "pie_patientfeedback": "Patient Feedback",
   "systitle": "Document Name / Title",
   "stp_title": "Title",
   "stp_description": "Description",
   "mr_information_classification": "Information Classification",
   "mr_stage_model_development": "Stage of Model Development",
   "mr_model_steward": "Model Steward",
    "mr_value_implementation_lead": "Value and Implementation Lead",
    "mr_country":"Country",
    "mr_name_product":"Name of Product",
     "mr_indication": "Indication/Disease",
     "mr_version_number": "Version Number",
     "mr_global": "Global/Adaptation",
     "mr_therapeutic_area": "Therapeutic Area",
     "mr_model_type":"Model Type"
}

const USER_CHOICE = {
   "title": "Document Name",
   "contentintendeduse": "Intended Use",
   "description": "Description",
   "source": "Source",
   "contentdocumentnumber": "Document Number",
   "contentdocumenttype": "Document Type",
   "gmsacountry": "Country",
   "contentdocumentsubtype": "Document Sub Type",
   "contentinclm": "Content In CRM",
   "contentinirms": "Content In MI",
   "Uri": "Source Link",
   "contentproductname": "Product",
   "vvmctherapeuticarea": "Therapeutic Area",
   "language": "Language",
   "contentproactive": "Proactive",
   "docOwner": "Document Owner",
   "gmsaapprovaldate": "Approval Date",
   "contentsourceid": "Document ID",
   "contentitemstatus": "Status",
   "vvmctherapeuticareaveap": "Therapeutic Area (VEAP)",
   "vvmcgvdveapid": "VEAP ID OR REV/OPS ID",
   "vvmcgvdkeycontact": "Key Contact",
   "vvmcgvddepartment": "Department (VEAP)",
   "vvmc_core_model_type": "Model Type",
   "vvmc_core_dcr_approval_date": "DRC Approval Date",
   "vvmc_core_activity_type": "Activity Type",
   "vvmcgvdeffectivedate": "Effective Date",
   "payer": 'Payor Use Only',
   "payerregion": 'Payor Region',
   "payertopic": 'Payor Topic',

   "niscompletiondate": "Planned/Actual Report Completion Date",
   "nisapprovaldate": "Planned/Actual Protocol Approval Date",
   "nisactivitystatus": "Activity Status (REV/OPS ID)",
   "veapactivitytype": "Activity type (REV/OPS ID)",
   "veapcountry": "Country (REV/OPS ID)",
   "veapcustomerbusline": "Customer Business Line",
   "veapisldg": "Is LDG? (REV/OPS ID)",
   "veapmkvnumber": "MK/V Number",
   "veapproduct": "Name of Product (REV/OPS ID)",
   "veapcontact": "Steward/Contact (REV/OPS ID)",
   "veap_obj_id": "REV/OPS ID",
   "veapstudyobjective": "Study Objective",
   "veaptherapeuticarea": "Therapeutic Area (REV/OPS ID)",
   "veapindication": "Indication/Disease (REV/OPS ID)",
   "veaptitle": "Title"
}

const VVQD_CHOICE = {
   "title": "Document Name",
   "uri": "Source Link",
   "syssource": "Source",
   "qddocumenttype": "Document Type",
   "qddocumentsubtype": "Document Sub Type",
   "contentdocumentnumber": "Document Number",
   "contentitemstatus": "Status",
   "mrlclassification": "MRL Classification",
   "qdsensitivityclassification": "Sensitivity Classification",
   "impactedcountry": "Impacted Countries/Regions",
   "contentversion": "Version",
   "qdimpacteddepartment": "Impacted Department",
   "qdeffectivedate": "Effective Date",
   "qdfinaldate": "Final Date",

}

const NEWS_CHOICE = {
   "title": "Document Name",
   "author": "Author",
   "printableUri": "Source Link",
   "contenttherapeuticarea": "Theurapetic Area",
   "contentspcategoryannouncements": "Announcements",
   "contentspcategoryfunctionalareas": "Functional Areas",
   "contentspcategorygmacapabilities": "GMA Capabilities",
   "contentspcategoryleadermessages": "Leader Messages",
   "gmsapublisheddate": "Published Date",
   "gmsaregion": "Region",
   "contentspcategoryspecialtopics": "Special Topics"
}


export const SYSTEM_MSGS = {
   "outage1": "System notification: <b style='text-decoration:underline;'>Action needed</b>: Please click on <b>Ctrl+Shift+R</b> to refresh your browser to ensure you have the most recent changes.",
   "outage2": "Please note that you only need to do this the first time accessing the system after the updates on 17 Nov 2023.",
}

export const features = {
   "searchsettings": false
}
