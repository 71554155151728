import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieServiceWrapper } from './cookie-service-wrapper.service';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from 'src/environments/environment';
import { CryptoService } from './crypto.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient:HttpClient, private cookieService:CookieServiceWrapper, private cryptoservice: CryptoService, private router: Router,) { }

  checkAuthetication(){
    var enAjx  = this.cookieService.get('ajs');
    if(!enAjx){
     this.router.navigate(['/auth']);
    }
  }

  async getAuthenticated(code:string){
    return new Promise(resolve =>{
      var response:any;
    // console.log("Authenticated function call-->"+code);
    var payload = { "code": code };
    this.getAuthenticatedAPICall(payload).subscribe((res)=>{
      console.log("AUTH RES-->"+JSON.stringify(res));
      // console.log("res.currentYear", res.body.currentYear);
      this.cookieService.set("currentYear",res.body.currentYear,1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
      resolve(res);
    })
    })
  }


  getUserIdOnly(){
    const usi = this.cookieService.get('usi');
    return usi;
  }

  getUserId(){
    const usi = this.cookieService.get('usi');
    var userId:any;
    if(usi){
       userId = usi;
    }else{
     this.checkAuthetication();
    }
    return usi;
  }

  getIsid(){
    const isi = this.cookieService.get('isi');
    var isid:any;
    if(isi){
      isid = this.cryptoservice.decryptData(isi);
    }
    return isi;
  }



  getAuthenticatedAPICall(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.AUTH.AUTH_SERVICE_URL, requestPayload);
  }

  getUserFromDBAPICall(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.AUTH.AUTH_GET_USER_DB_SERVICE_URL, requestPayload);
  }

  lastLoggedIn(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.AUTH.AUTH_USER_LAST_LOGGED_IN, requestPayload);
  }

  async getMaintenanceStatus(requestPayload:any){
    return  await this.httpClient.post(ENVIRONMENT.AUTH.AUTH_SERVICE_URL, requestPayload).toPromise();
  }
  
 async checkForMaintenance(){
  var isInMaintenance = sessionStorage.getItem("isInMaintenance");
  if(isInMaintenance){
           let checkForRedirection = Boolean(isInMaintenance); 
             if(checkForRedirection){
               alert("redirecting to maintenance page");
             }
  }
  else{
    // console.log("setting isInMaintenance flag");
    var resp = await this.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
           resp = Boolean(resp);
           // console.log("typeof resp", typeof resp);
         if(resp){
          sessionStorage.setItem("isInMaintenance", "true");
          this.router.navigateByUrl("/maintenance");
          return;
         }
  }
 }

 getUserCountryMap(requestPayload:any): Observable<any>{
  return this.httpClient.post(ENVIRONMENT.AUTH.GET_USER_COUNTRY_MAP, requestPayload);
}

  // getUserCountry(requestPayload:any): Observable<any>{
  //   // return this.httpClient.get("https://iapi.merck.com/internal-directory/v2/users/chilukua");

  //   return this.httpClient.request('GET', "https://iapi.merck.com/internal-directory/v2/users/chilukua" , {responseType:'json'});
  // }


  // getUserCountry(isid, token){
  //   return new Promise(function(resolve, reject) {
  //     var url =  "https://iapi.merck.com/internal-directory/v2/users/chilukua";
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("GET", url);
  //   xhr.setRequestHeader("Accept", "application/json");
  //   xhr.setRequestHeader("Content-Type", "application/json");
  //   xhr.setRequestHeader("X-Merck-APIKey", "vb75zNWFdbI9zUEj2yv6RgkhjFh5lsxC");
  //   xhr.onreadystatechange = function () {
  //    if (xhr.readyState === 4) {
  //       // console.log(xhr.status);
  //       // console.log(xhr.responseText);
  //       // alert(xhr.responseText);
  //       resolve(xhr.responseText);
  //    }};
  //    var data = ``;
  //    xhr.send(data);
  //   });
  // }



}
