<br><br><br><br><br><br><br><br><br><br><br><br>
<form style="margin-left:100px" (ngSubmit)="onSubmit()">
   
        <label for="username">Email Id:</label>&nbsp;
        <input size="30" name="email" type="text" [(ngModel)]="email"   /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      
   
  
        <label for="password">ISID:</label>&nbsp;
        <input size="30" name="isid" type="text" [(ngModel)]="isid"  />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       
       
  
  
        <button>
            Go to CSN
        </button>
      
   
</form>

<br><br><br><br>

<h6 style="color:red; margin-left:100px">Note: This page is a temporary. This page will be removed once the authentication is implemted</h6>
