import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { CryptoService } from '../crypto.service';
import {Router} from "@angular/router";
import { CONFIG } from 'src/environments/environment';
import { ENVIRONMENT } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})


export class AuthenticationComponent implements OnInit {

  code!:string;
  href:any;

  constructor(private router: Router,
    private route: ActivatedRoute,
     private authService:AuthService, 
     private cookieService: CookieServiceWrapper,
      private cryptoservice:CryptoService,
      private cookie:CookieService,
      private configService : ConfigService
      ){
  // console.log("Auth component called");
  this.deleteAllCookies("/","."+ENVIRONMENT.DOMAIN.HOST);  

  this.clearCacheStorage().then( () =>{
    //  console.log("cache cleared");
  },
  (error) =>{
     console.log("error in cache",error);
  }
  )
   }

    async clearCacheStorage() {
    if ("caches" in window) {
        const cacheNames = await caches.keys();
        // console.log(cacheNames);
        for (const cacheName of cacheNames) {
            await caches.delete(cacheName);
        }
        console.log("All caches cleared.");
    } else {
        console.log("Cache Storage API is not available in this browser.");
    }
}

  deleteAllCookies(path,domain) {



  const cookies = document.cookie.split(';');
 
  
for (const cookie of cookies) {
     const cookieName = cookie.split('=')[0].trim();
     if(cookieName!='redirect'){
       document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'+(path?";path="+path:"")+(domain?";domain="+domain:"");
     }
     }   
     console.log("cleared cookies");
  }

 async  ngOnInit() {
    // alert("CALLED");

    // this.getUserCountry("chilukua",this.cookieService);
    

    var response = await this.configService.loadConfig().toPromise();

  

    this.cookieService.set("proxyxmerckapi", response.proxyxmerckapi,1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    this.cookieService.set("proxyBase64", response.proxyBase64,1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict')

     

   this.getCodeFromURL();
  }

  getCodeFromURL(){
    this.route.queryParams.subscribe(async params => {
      try{
      this.code = params['code'];
      this.href = this.router.url;


      if(this.router.url.includes('error')){
        this.router.navigateByUrl("/error", 
          {
           state: {
            //  message: "You do not have access to the Search Navigator."
            message: CONFIG.AUTHENTICATION.ACCESS_RESTRICTION
           }
          })
          return;
      }

      if(this.code){
        // console.log("this.code-->"+this.code);
        // this.cookieService.set('code', this.code);
        this.cookieService.set('code', this.code, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
        this.authService.getAuthenticated(this.code).then((authResponse:any) => {
           // console.log("authResponse-->"+JSON.stringify(authResponse));

           
           try{
            if(authResponse.body.isRockyMountainUser){
              this.cookieService.set('rkymnt', "rkm", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            }else{
              this.cookieService.set('rkymnt', "", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            }
          }catch(Exception){
            // console.log("Exception-->"+Exception)
          }


          try{
            if(authResponse.body.isHha){
              this.cookieService.set('hha', "hha", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            }else{
              this.cookieService.set('hha', "", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            }
          }catch(Exception){
            // console.log("Exception-->"+Exception)
          }


          try{
            if(authResponse.body.isMrl){
              this.cookieService.set('mrl', "mrl", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            }else{
              this.cookieService.set('mrl', "", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            }
          }catch(Exception){
            // console.log("Exception-->"+Exception)
          }
               
          var encAuthJson =  this.cryptoservice.encryptData(JSON.stringify(authResponse));
          // // console.log("encAuthJson-->"+JSON.stringify(encAuthJson));
          // this.cookieService.set('ajs', encAuthJson);
          // this.cookieService.set('ajs', encAuthJson, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
          this.cookieService.set('ajs', 'y', 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
          // console.log("SUCCESSFULLY Auth");
          // this.cookieService.set("currentYear",authResponse.currentYear,1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
          const ajs = this.cookieService.get('ajs');
          // // console.log("ajs-->"+JSON.stringify(ajs));

          //var decAuthJson =  this.cryptoservice.decryptData(ajs);
          // console.log("decAuthJson-->"+JSON.stringify(decAuthJson));
          
        if(authResponse.statusCode ==200){
          history.pushState(null, '');
          this.getUserFromDB(authResponse.body.email,authResponse.body.isid).then((userDetails:any)=>{
            var userId = userDetails.id;
            var isid = userDetails.isid;
            // console.log("userDetails -->", JSON.stringify(userDetails));

           
            var that = this;
            this.getUserCountry(isid,this.cookieService,that);

            var enuserId =  this.cryptoservice.encryptData(JSON.stringify(userId));

           
            
            
            this.cookieService.set('email', authResponse.body.email, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            // this.cookieService.set('usi', userId);
            this.cookieService.set('usi', userId, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            // this.cookieService.set('st',authResponse.body.jwt_token);
            this.cookieService.set('st',authResponse.body.jwt_token, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            // this.cookieService.set('sn', authResponse.body.name);
            this.cookieService.set('sn', authResponse.body.name, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            // this.cookieService.set('fn', authResponse.body.fullName);
            this.cookieService.set('fn', authResponse.body.fullName, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            //  Set Org ID 
            this.cookieService.set('xmerckapikey', authResponse.body.xmerckapikey, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            this.cookieService.set('oid', authResponse.body.orgId, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            this.cookieService.set('esk', authResponse.body.privateesk, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            

            

            // this.cookieService.set('se', authResponse.body.encryptDecryptKey);
            this.cookieService.set('se', authResponse.body.encryptDecryptKey, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            // this.cookieService.set('ses', authResponse.body.xApiKey);
            this.cookieService.set('ses', authResponse.body.xApiKey, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

            // this.cookieService.set('hct', authResponse.body.hideContentType);
            this.cookieService.set('hct', authResponse.body.hideContentType, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            // this.cookieService.set('hcst', authResponse.body.hideContentSubType);
            this.cookieService.set('hcst', authResponse.body.hideContentSubType, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

            this.cookieService.set('sogvd', authResponse.body.contentType, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');


            // this.cookieService.set('rkymnt', authResponse.body.isRockyMountainUser==true?"ROCKYMOUNTAIN":"", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');


            var enisid =  this.cryptoservice.encryptData(JSON.stringify(isid));
            var uc =  this.cryptoservice.encryptData(userDetails.userContext);
            // this.cookieService.set('isi', isid);
            var encryptedIsid =  this.cryptoservice.encryptData(isid);
            this.cookieService.set('isi', encryptedIsid, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            // this.cookieService.set('uc', uc);
            this.cookieService.set('uc', uc, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            var isAdmin = userDetails.isAdmin;
            // console.log("isAdmin", isAdmin);
            // this.cookieService.set('isAdmin', isAdmin);
            this.cookieService.set('isAdmin', isAdmin, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            // console.log("authResponse.isInMaintenance type", typeof authResponse.body.isInMaintenance);
            // console.log("userDetails.maintenanceAccess",  userDetails.maintenanceAccess);


            let redirect:any = this.cookieService.get("redirect");
            if(redirect){
              // // console.log("redirect called--->"+redirect);
              // this.cookieService.set("redirect","");
              this.cookieService.set("redirect","", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
              this.cookieService.set("ucon","", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
              this.router.navigateByUrl(redirect,{
                state: {
                  redirect: true
                }
              });
            }else{
              this.router.navigate(['/landing']);
            }


           
            if(authResponse.body.isRockyMountainUser == "true" || authResponse.body.isRockyMountainUser == true){
              if(authResponse.body.isInMaintenance == "true" || authResponse.body.isInMaintenance == true){
                this.router.navigateByUrl("/maintenance");
                      return;
              }
              sessionStorage.setItem("isRockyMountainUser", "true");
              //this.router.navigateByUrl("/share/searches/"+authResponse.body.rockyMountainSavedSearchId);
              this.router.navigateByUrl("/");
              return;
             }
             else{
              sessionStorage.setItem("isRockyMountainUser", "false");
             }
           
               if(authResponse.body.isInMaintenance == "true" || authResponse.body.isInMaintenance == true){
                // console.log("Inside");
                
                     if(userDetails.maintenanceAccess == 0){
                      // console.log("inside maintenance this")
                      
                      this.router.navigateByUrl("/maintenance");
                      return;
                     }
                     else{
                      sessionStorage.setItem("maintenanceAccess", "1");
                     }
               }

            if(localStorage.getItem("urlredirection")){
                    if(isAdmin == 0){
                          localStorage.removeItem("urlredirection");
                      this.router.navigateByUrl("/error", 
          {

           state: {
            //  message: "Your access to Search Navigator has been removed due to your recent organization move. If this is an error, please submit a help desk ticket to restore access." 
            message: encodeURIComponent(CONFIG.AUTHENTICATION.NOT_ADMIN)
           }
          })
          return;
                    }

                    if(isAdmin == 1){
                     // localStorage.removeItem("urlredirection");
                  this.router.navigateByUrl("/userdep");
                  return;
                }
                }
            // localStorage.setItem('nickname', 'SK');
            // let redirect:any = this.cookieService.get("redirect");
            // if(redirect){
            //   // // console.log("redirect called--->"+redirect);
            //   // this.cookieService.set("redirect","");
            //   // this.cookieService.set("redirect","", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
            //   this.router.navigateByUrl(redirect,{
            //     state:{}
            //   });
            // }else{
            //   this.router.navigate(['/landing']);
            // }
            
          })
          
          // var encAuthJson =  this.cryptoservice.encryptData(JSON.stringify(authResponse));
          
        }else if(authResponse.statusCode ==400){
          // console.log("Deprovisioned user Auth");
          // this.router.navigate(['/'])
          this.router.navigateByUrl("/error", 
          {
           state: {
            //  message: "Your access to Search Navigator has been removed due to your recent organization move. If this is an error, please submit a help desk ticket to restore access." 
            message: encodeURIComponent(CONFIG.AUTHENTICATION.USER_DEPROVISIONED)
           }
          })
        }else{
          // console.log("Failed Auth");
          // this.router.navigate(['/'])
          this.router.navigateByUrl("/error", 
          {
           state: {
            //  message: "You do not have access to the Search Navigator."
            message: CONFIG.AUTHENTICATION.ACCESS_RESTRICTION
           }
          })
        }
        })
      }
      else{
        // alert("this.code-->missing");
        // window.location.href ="https://gmsasrch-sit.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=71eooru3q0dut7pgjoki9oq79p&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Fgmsasearch-sit.merck.com%2Fauth"
        // window.location.href ="https://gmsasrch-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=teumufnpug3qo42ibrmfgjpr7&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Fgmsasearch-dev.merck.com%2Fauth"
        window.location.href = CONFIG.AUTHENTICATION.ACTIVE_DIRECTORY_URL
      }
    }catch(err){
      // window.location.href ="https://gmsasrch-sit.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=71eooru3q0dut7pgjoki9oq79p&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Fgmsasearch-sit.merck.com%2Fauth"
      // window.location.href ="https://gmsasrch-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=teumufnpug3qo42ibrmfgjpr7&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Fgmsasearch-dev.merck.com%2Fauth"
      // window.location.href = CONFIG.AUTHENTICATION.ACTIVE_DIRECTORY_URL
    }
  });
  }
  getUserCountry(isid:string, cookieService, that){
    let countryRes = "";
    try{
      return new Promise(function(resolve, reject) {
       // var url =  "https://iapi.merck.com/internal-directory/v2/users/"+isid;
        var url =  `${CONFIG.AUTHENTICATION.GET_USER_COUNTRY}`+isid;
        // if(cookieService.get('xmerckapikey'))
        // {
        //   var xmerckapikey:any = cookieService.get('xmerckapikey')

        // }
        // else{
          var xmerckapikey:any = ENVIRONMENT.AUTH.XMERCKAPIKEY

        // }
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Content-Type", "application/json");
      // xhr.setRequestHeader("X-Merck-APIKey", 'vb75zNWFdbI9zUEj2yv6RgkhjFh5lsxC');
      xhr.setRequestHeader("X-Merck-APIKey", xmerckapikey);
      xhr.onreadystatechange = function () {
       if (xhr.readyState === 4) {
          // console.log(xhr.status);
          // console.log(xhr.responseText);
          countryRes = xhr.responseText;
  
          
          // cookieService.set("ucon",JSON.parse(countryRes).country);
          try{
            let countryName = JSON.parse(countryRes).country;
            that.getUserCountryMap(countryName).then((countryRes:any) => {
              var countryNameFromMap = countryRes.body;
              if(countryNameFromMap){
                cookieService.set("ucon",countryNameFromMap, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
              }else{
                cookieService.set("ucon",countryName, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
              }
              
            })
          // if(countryName == 'United States'){
          //    countryName = 'United States;United States of America';
          // }
          cookieService.set("ucon",countryName, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
          // cookieService.set("ucon",JSON.parse(countryRes).country, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
  
          }catch(err){
            // console.log("error while processing country from AD ---"+err);
          }
          
  
          // callback(countryRes);
          // alert(xhr.responseText);
          resolve(xhr.responseText);
       }};
       var data = ``;
       xhr.send(data);
  
       return countryRes;
      });

    }catch(err){
      // console.log("error while fetching country from AD ---"+err);
    }
    return countryRes;
    
  }

  getUserFromDB(email:string,isid:string){
    return new Promise(resolve =>{
      var data = `{
        "email": "`+email+`",
        "isid": "`+isid+`"
      }`;
      this.authService.getUserFromDBAPICall(data).subscribe((userDetails)=>{
        // console.log("userDetails RES-->",userDetails);
        this.lastLoggedIn(userDetails?.id)
        resolve(userDetails);
      })
    })
  }

  getUserCountryMap(country:string){
    return new Promise(resolve =>{
      var data = `{"country": "`+country+`"}`;
      this.authService.getUserCountryMap(data).subscribe((userCountryMap)=>{
        // console.log("userCountryMap RES-->",userCountryMap);
        resolve(userCountryMap);
      })
    })
  }

  lastLoggedIn(userId)
  {
    
    try{
      let req = {
        "userId": userId
      }
      this.authService.lastLoggedIn(req).subscribe((userDetails)=>{
        // console.log("Last Logged in updated", userDetails);
      })
    }
      
    catch(error){
                   // console.log("error", error);
    }
  }
    
}
