

<span *ngIf="checkOneDriveStatus(result);else unfilled" (click)="removeFromOneDrive();customEventRemoveOneDrive(result)" class="buttonsStyle">
    <span class="botton-action-icons" title="Click to remove document from OneDrive">
        <!-- <img  src={{onedrive_icon_filled}}> -->
        <span class="material-icons outlined" style="color: #00857c;">
            cloud
        </span>
        
    </span><span class="botton-action-labels" title="Click to remove document from OneDrive">Remove from OneDrive</span>
</span>
<ng-template #unfilled>
    <span class="buttonsStyle" (click)="addToOneDrive(result);customEventAddOneDrive(result)">
        <span class="botton-action-icons" title="Click to add document to OneDrive">
            <!-- <img  src={{onedrive_icon_unfilled}}> -->
            <span class="material-icons outlined" style="color: #00857c;">
                cloud_queue
            </span>
        </span><span class="botton-action-labels" title="Click to add document to OneDrive">Add to OneDrive</span>
    </span>
</ng-template>




<div *ngIf="addToOneDriveFlag">
    <div id="popup1" class="overlay" (click)="closePopup()"></div>
        <div class="popup">
            <!-- <h2>Here i am</h2> -->
            
            <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
            <div class="content">
                <div style="display: flex; flex-direction: row;">
                    <!-- <div style="flex-basis: 25%;"><div class="oneDriveInfoQuestionIcon">?</div></div> -->
                    <div>
                        <div class="popup-title">{{title}}</div>
                        <div class="popup-question"><div>{{userText}}</div></div>
                        <div class="popup-action-section"> <button  (click)="closePopup()"  class="docFavCancleBtn ">Close</button><button id="addToOneDriveId" class="docFavSaveBtn" style="width: 6rem;" (click)="addToOneDriveServver()">Download</button></div>
                        <div class="progress-message">{{this.message}}</div>
                        <div class="disclaimer">{{onedriveInfoMessage}}</div>
                    </div>
                </div>
            </div>
        </div>
</div>

<div *ngIf="oneDriveDownloadOperation">
    <div id="popup1" class="overlay" (click)="closePopup()"></div>
        <div class="popup">
            <div class="content">
                <div style="display: flex; flex-direction: row;">
                    <div>
                        <div class="popup-title">Download to OneDrive</div>
                        <div class="popup-question"><div>{{downloadInProgress}}</div></div>
                        <div class="popup-action-section"> <button  (click)="oneDriveDownloadOperationPopup()"  class="docFavCancleBtn ">Close</button></div>
                        <div class="progress-message">{{this.message}}</div>
                        <div class="disclaimer">{{onedriveInfoMessage}}</div>
                    </div>
                </div>
            </div>
        </div>
</div>


<div *ngIf="oneDriveRemovalOperation">
    <div id="popup1" class="overlay" (click)="closePopup()"></div>
        <div class="popup">
            <!-- <h2>Here i am</h2> -->
            
            <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
            <div class="content">
                <div style="display: flex; flex-direction: row;">
                    <!-- <div style="flex-basis: 25%;"><div class="oneDriveInfoQuestionIcon">?</div></div> -->
                    <div>
                        <div class="popup-title">Remove from OneDrive</div>
                        <div class="popup-question"><div>Removal of document(s) from One Drive is in progress, please wait before you select more.</div></div>
                        <div class="popup-action-section"> <button  (click)="oneDriveRemovalOperationPopUp()"  class="docFavCancleBtn ">Close</button></div>
                        <div class="progress-message">{{this.message}}</div>
                        <div class="disclaimer">{{onedriveInfoMessage}}</div>
                    </div>
                </div>
            </div>
        </div>
</div>

<style>
.disclaimer{
    font-size:11px;
}
.progress-message{
    color: red;
    font-size:11px;
}
</style>


<div *ngIf="addToOneDriveMessage">
    <div id="popup2" class="overlay"></div>
        <div class="popup" style="max-height: 320px;">
            <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
            <div class="content">
                <div style="display: flex; flex-direction: row;">
                    <!-- <div style="flex-basis: 25%;"><div class="oneDriveInfoQuestionIcon">&check;</div></div> -->
                    <div>
                        <div class="popup-title">{{title}}</div>
                        <div class="popup-question"><div>{{message}} <span *ngIf="failedListDocs.length > 0" style="color:red; font-weight: 700;">{{failedListDocs.length}}</span>{{message1}}</div></div>
                        <div *ngIf="checkLength(failedListDocs)" style="display: flex; flex-direction: column;">
                            <!-- <div style="font-weight: 700;">Failed documents:</div> -->
                            <div *ngFor="let failedListDoc of failedListDocs; index as i">
                                <div style="font-size: 14px; padding: 5px; color:teal;font-weight: 700;">
                                  {{i+1}}. {{failedListDoc.name}}
                                </div>
                            </div>

                        </div>
                        <div class="popup-action-section"><span></span><button class="docFavSaveBtn" style="width:6rem" (click)="closeAndRefresh()">Close</button></div>
                        <div class="disclaimer">{{onedriveInfoMessage}}</div>
                    </div>
                </div>
            </div>
        </div>
</div>



<div *ngIf="removeFromOneDriveFlag">
    <div id="popup3" class="overlay" (click)="closePopup()"></div>
        <div class="popup">
            <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
            <div class="content">
                <div style="display: flex; flex-direction: row;">
                    <!-- <div style="flex-basis: 25%;"><div class="oneDriveInfoRedIcon">!</div></div> -->
                    <div>
                        <div class="popup-title">{{title}}</div>
                        <div class="popup-question"><div>{{userText}}</div></div>
                        <div class="popup-action-section"> <button  (click)="closePopup()"  class="docFavCancleBtn">Close</button><button class="docFavSaveBtn" 
                            id="removeFromOneDriveId"  (click)="removeFromOneDriveServver()">Remove</button></div>
                        <div class="progress-message">{{this.message}}</div>
                        <div class="disclaimer">{{onedriveInfoMessage}}</div>
                    </div>
                </div>
            </div>
        </div>
</div>
