import { Component, OnInit, inject,OnDestroy } from '@angular/core';
import { UserDeprovisioningService } from '../user-deprovisioning.service';
import {DOCUMENT} from '@angular/common';
import { COLUMN_MAPPING, CONFIG, ENVIRONMENT } from 'src/environments/environment';
import * as XSLX from 'xlsx';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { Customeventlogging } from '../customevent-logging.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-deprovisioning',
  templateUrl: './user-deprovisioning.component.html',
  styleUrls: ['./user-deprovisioning.component.css']
})
export class UserDeprovisioningComponent implements OnInit,OnDestroy {
  private document:any = inject(DOCUMENT);
  loadingMessage:any = CONFIG.USER_DEPROVISIONING.LOADING_MESSAGE
   users:any =[];
   exportUsersData:any=[];
   userDeprovPage = true;
   userId:any;

  constructor(private userDeprovisioning:UserDeprovisioningService, 
    private eventLoggingService :Customeventlogging,
    private cookieService: CookieServiceWrapper,
    private authService: AuthService,
    private router: Router){

      
   
  }

   dateFormat (date) {
    var date:any = new Date(date)
    return (
      this.addZero(date.getMonth() + 1) +
      '/' +
      this.addZero(date.getDate()) +
      '/' +
      date.getFullYear()
    )
  }

  exportUsersToExcel(){

    var date = new Date()
        var exportUsers = this.exportUsersData;
        var finalExportUsers:any = [];
        exportUsers.forEach((user, i) => {
          finalExportUsers.push({
            'Sl.No': i + 1,
            ISID: user.ISID,
            'First Name': user['First Name'],
            'Last Name': user['Last Name'],
            'Manager Name': user['Manager Name'],
            'Deprovisioned Date': user['Deprovisioned Date']
          })
        })
        // console.log('finalExportUsers', finalExportUsers);

        const ws:XSLX.WorkSheet =   XSLX.utils.json_to_sheet(finalExportUsers);
  const wb:XSLX.WorkBook = XSLX.utils.book_new();
  XSLX.utils.book_append_sheet(wb,ws,'deprovisioned users');
  XSLX.writeFile(wb,  "Userdeprovisioning" + "_" + this.getTimeStamp()+".xlsx");

        // const EXCEL_TYPE =
        //   'applicationvnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        // const EXCEL_EXTENSION = '.xlsx'

        // const worksheet = XLSX.utils.json_to_sheet(finalExportUsers)
        // const workbook = {
        //   Sheets: {
        //     User_Deprovisioning_Data: worksheet
        //   },
        //   SheetNames: ['User_Deprovisioning_Data']
        // }
        // const excelBuffer = XLSX.write(workbook, {
        //   bookType: 'xlsx',
        //   type: 'array'
        // })
        // // console.log(excelBuffer)
        // const data = new Blob([excelBuffer], { type: EXCEL_TYPE })
        // var fileName = 'User_Deprovisioning' + getTimeStamp()
        // saveAs(data, fileName + EXCEL_EXTENSION)

  }

  

  async ngOnInit() {
    // this.userId = this.authService.getUserId();
    // if(!this.userId){
      // this.cookieService.set("usd", "1");
      // // console.log("cookie is set");
      // // console.log("usd",this.cookieService.get("usd"));
            if(localStorage.getItem("urlredirection") ==  null)
            {
      localStorage.setItem("urlredirection","/userdep");
     this.router.navigate(['/auth']);
     return;
            }

    //  return;
   //}
    if(this.cookieService.get("isAdmin") == "0")
    {
      this.router.navigateByUrl("/error", 
      {
       state: {
        //  message: "Your access to Search Navigator has been removed due to your recent organization move. If this is an error, please submit a help desk ticket to restore access." 
        message: encodeURIComponent(CONFIG.AUTHENTICATION.NOT_ADMIN)
       }
      })
      return;
    }
    // if( this.document.getElementById("adminId")){
    //   // console.log("inside");
    // this.document.getElementById("adminId").style.display= "none";
    // }
    // this.document.getElementById("nickname").innerHTML = localStorage.getItem("nickname");
    // this.document.getElementById("nickname").innerHTML = this.cookieService.get("sn");
    // this.document.getElementById("fullname").innerHTML = this.cookieService.get("fn");
    this.defaultDates();
    this.maxDate();
    this.getUsers();
    //localStorage.removeItem("urlredirection");
  // var users = 
   //// console.log("users", users);
  }
  ngOnDestroy(): void {
    // console.log("ngdestroy called");
   // localStorage.removeItem("urlredirection");
    if(this.cookieService.get("usd")){
      this.cookieService.delete("usd");
    }
  }

   defaultDates(){
    // console.log("default dates called");
    var today = new Date();
var dd = this.addZero(today.getDate());
var mm = this.addZero(today.getMonth()+1); 
var yyyy = today.getFullYear();
var endDate = yyyy+'-'+mm+'-'+dd;
    this.document.getElementById('endDate').defaultValue=endDate;
    this.document.getElementById('startDate').defaultValue = yyyy+"-"+"01"+"-"+"01";
  }

   addZero (input) {
    if (input < 10) {
      input = '0' + input
    }
    return input
  }

   maxDate () {
    var date = new Date()
    this.document.getElementById('startDate').max =
      date.getFullYear() +
      '-' +
      this.addZero(date.getMonth() + 1) +
      '-' +
      this.addZero(date.getDate())
    this.document.getElementById('endDate').max =
      date.getFullYear() +
      '-' +
      this.addZero(date.getMonth() + 1) +
      '-' +
      this.addZero(date.getDate())
  }

   disableExportToExcel () {
    this.document.getElementById('exportToExcelBtn').disabled = true
    this.document.getElementById('exportToExcelBtn').style.backgroundColor =
      'grey'
  }

   enableExportToExcel () {
    this.document.getElementById('exportToExcelBtn').disabled = false
    this.document.getElementById('exportToExcelBtn').style.backgroundColor =
      'teal'
  }

   getUsers = async () => {
    localStorage.removeItem("urlredirection");
   var allUsers;
    this.enableExportToExcel()
    this.document.getElementById('dataTable').style.display = 'none'
    this.document.getElementById('loader').style.display = 'block'
    try {
       allUsers =  await  this.userDeprovisioning.getNotificationsCount().toPromise();
    } catch (error) {
      // console.log('error', error)
      this.disableExportToExcel()
      // this.document.getElementById('loader').innerHTML =
      //   'Something went wrong! Please try again after some time'
      this.document.getElementById('loader').innerHTML =CONFIG.USER_DEPROVISIONING.SOMETHING_WENT_WRONG
        
      return
    }

    // console.log('allUsers', allUsers);
    //allUsers = [];

    if (allUsers.length == 0) {
      this.disableExportToExcel()
      // this.document.getElementById('loader').innerHTML = 'No Data Available'
      this.document.getElementById('loader').innerHTML = CONFIG.USER_DEPROVISIONING.NO_DATA_aVAILABLE
      return
    }

    allUsers.forEach(user => {
      this.users.push({
        ISID: user.ISID,
        'First Name': user['First Name'],
        'Last Name': user['Last Name'],
        'Manager Name': user['Manager Name'],
        'Deprovisioned Date': user['Deprovisioned Date']
      })
    })

    var tableHeaders = [
      'Sl.No',
      'ISID',
      'First Name',
      'Last Name',
      'Manager Name',
      'Deprovisioned Date'
    ]

    var tableHead:any = document.getElementById('users_thead')

    var tableHeadersContent = ''
    tableHeaders.forEach(tableHeader => {
      tableHeadersContent += '<th style="padding: 0.5rem;">' + tableHeader + '</th>'
    })
    // console.log(tableHeadersContent)
    tableHead.innerHTML = tableHeadersContent

    var tableBodyContent = ''

    var userKeys = Object.keys(this.users?.[0])

    var sequenceNumber = 0
    this.users.forEach((user, i) => {
      // "<tr>"+  +"</tr>"
      var tableRow = '<tr>'
      userKeys.forEach((key, i) => {
        if (i == 0) {
          tableRow = '<td>' + (sequenceNumber + 1) + '</td>'
          sequenceNumber++
        }
        if (key == 'Deprovisioned Date') {
          user[key] = this.dateFormat(user[key])
        }

        tableRow += '<td>' + user[key] + '</td>'
      })
      tableRow = tableRow + '</tr>'
      // console.log(tableRow)
      tableBodyContent += tableRow
    })
    // console.log(tableBodyContent)
    this.exportUsersData = this.users;

    var tableBody:any = document.getElementById('users_tbody')
    tableBody.innerHTML = tableBodyContent
    this.document.getElementById('dataTable').style.display = 'block'
    this.document.getElementById('loader').style.display = 'none'

    this.defaultDates();
    this.filterUsers();

  }

  async  filterUsers () {
    this.enableExportToExcel()
    //// console.log('users', users)
    this.document.getElementById('dataTable').style.display = 'none'
    this.document.getElementById('loader').style.display = 'block'

    var startDate = this.document.getElementById('startDate').value
    var endDate = this.document.getElementById('endDate').value

    if (startDate.length == 0 && endDate.length == 0) {
      this.users = []
      await this.getUsers()
      return
    }

    if (startDate.length == 0) {
      this.disableExportToExcel()
      // this.document.getElementById('loader').innerHTML =
      //   'Start date is required. Please select start date'
      this.document.getElementById('loader').innerHTML =CONFIG.USER_DEPROVISIONING.START_DATE
      return
    }
    // console.log('startDate', startDate)
    // console.log('endDate', endDate)
    if (endDate.length == 0) {
      var date = new Date();
      endDate =
        date.getFullYear() +
        '-' +
        this.addZero(date.getMonth() + 1) +
        '-' +
        this.addZero(date.getDate())
      // console.log('end date', endDate)
    }

    var startDateparts = startDate.split('-');
      var endDateParts = endDate.split('-');
      // console.log("startDateparts", startDateparts);

    startDate = new Date(startDateparts?.[0], startDateparts?.[1]-1,startDateparts?.[2]);
    endDate = new Date(endDateParts?.[0], endDateParts?.[1]-1,endDateParts?.[2])
   // endDate = new Date(endDate)

    // console.log("start date updated", startDate); 
    // console.log("end date", endDate);

    // startDate = new Date(startDate)
    // endDate = new Date(endDate)

    if (endDate.getTime() < startDate.getTime()) {
      this.disableExportToExcel()
      // this.document.getElementById('loader').innerHTML =
      //   'End date cannot be less than start date'
      this.document.getElementById('loader').innerHTML = CONFIG.USER_DEPROVISIONING.END_DATE
      return
    }

    var filteredUsers = this.users.filter(user => {
      return (
        new Date(user['Deprovisioned Date']).getTime() >=
          startDate.getTime() &&
        new Date(user['Deprovisioned Date']).getTime() <=
          endDate.getTime()
      )
    })

    // console.log('filteredUsers', filteredUsers)
    // console.log('filteredUsers length', filteredUsers.length)

    this.exportUsersData = filteredUsers

    if (filteredUsers.length == 0) {
      this.disableExportToExcel()
      // this.document.getElementById('loader').innerHTML =
      //   'No data available for the selected period'
      this.document.getElementById('loader').innerHTML = CONFIG.USER_DEPROVISIONING.NO_DATA_AVAILABLE_FOR_SELECTION
        
      return
    }
    var tableBodyContent = ''
    var userKeys = Object.keys(this.users?.[0])
    // console.log('userKeys', userKeys)
    var sequenceNumber = 0
    filteredUsers.forEach((user, i) => {
      // "<tr>"+  +"</tr>"
      var tableRow = '<tr>'
      userKeys.forEach((key, i) => {
        if (i == 0) {
          tableRow = '<td style="padding: 0.5rem;">' + (sequenceNumber + 1) + '</td>'
          sequenceNumber++
        }
        if (key == 'Deprovisioned Date') {
          user[key] = this.dateFormat(user[key])
          // console.log('user[key]', user[key])
        }

        tableRow += '<td style="padding: 0.5rem;">' + user[key] + '</td>'
      })
      tableRow = tableRow + '</tr>'
      // console.log(tableRow)
      tableBodyContent += tableRow
    })
    // console.log(tableBodyContent)

    var tableBody:any = document.getElementById('users_tbody')
    tableBody.innerHTML = tableBodyContent

    this.document.getElementById('dataTable').style.display = 'block'
    this.document.getElementById('loader').style.display = 'none'
  }

   getTimeStamp () {
    const monthNames = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUNE',
      'JULY',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC'
    ]
    var date = new Date()
    var customDate:any = date.getDate()
    if (customDate <= 9) {
      customDate = '0' + customDate
    }
    var timeStamp =
      date.getFullYear() + monthNames[date.getMonth()] + customDate
    return timeStamp
  }

  backToResults(){

  }
 
  customEventDate()
  {
    this.eventLoggingService.logCustomEvent('User Deprovision Page', { userId: this.cookieService.get('usi'),Action:'End Date Picker'},'User-Deprovision-End-DatePicker');

  }

  customEventExport()
  {
    this.eventLoggingService.logCustomEvent('User Deprovision Page', { userId: this.cookieService.get('usi'),Action:'Export Excel'},'User-Deprovision-Export-Excel');

  }
  customEventDateStart()

  {

    this.eventLoggingService.logCustomEvent('User Deprovision Page', { userId: this.cookieService.get('usi'),Action:'Start Date Picker'},'User-Deprovision-Start-DatePicker');


  }

  

}

