import { Pipe, PipeTransform } from '@angular/core';
import {format, parse} from 'date-fns';

@Pipe({
  name: 'customdate'
})
export class CustomdatePipe implements PipeTransform {

  transform(value: string): string {
    if(!value){
      return value;
    }
    var parts = value.split(".");

           var year=parts?.[0];
           var month=parts?.[1];
           var day =parts?.[2];

           let monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];
           let monthName =monthNames[parseInt(month,10) - 1];
          let formattedDate =  `${monthName}-${day}-${year}`;
  return formattedDate;
}

}
