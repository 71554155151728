import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl,Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ViewpageService } from '../viewpage.service';
import { Result } from '@coveo/headless';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CryptoService } from '../crypto.service';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { CONFIG } from 'src/environments/environment';

@Component({
  selector: 'app-viewpage',
  templateUrl: './viewpage.component.html',
  styleUrls: ['./viewpage.component.css']
})
export class ViewpageComponent implements OnInit{

  safeUrl:any;
  dynamicTitle:any;
  // url = "https://sb-merck-medcomm-uat.veevavault.com/ui/approved_viewer?api=true&token=82813-c548da80-4497-4528-9740-d30b35e045c5&email=undefined&orgid=undefined&canDownload=false";

  // url = "https://sb-msd-medcomm-uat.veevavault.com/ui/approved_viewer?api=true";
  // url = "//sb-msd-medcomm-uat.veevavault.com/ui/approved_viewer?api=true";
  url = CONFIG.VIEWPAGE.URL
  overlapsize:any;
  docId: any;

  constructor(private sanitizer: DomSanitizer,private titleService: Title, private viewPageService: ViewpageService, private route: ActivatedRoute, private cryptoService:CryptoService, private cookieService: CookieServiceWrapper, private router: Router){
  }

  ngOnInit(): void {
    try{
     
      // var enAjx  = this.cookieService.get('ajs');
      // if(!enAjx){
      //   this.router.navigate(['/auth']);
      // }  
      
      
    var stringVal:string  = (this.route.snapshot.paramMap.get('doc'))!;
    // this.docId = this.cryptoService.decryptData(decodeURIComponent(stringVal));
    // this.dynamicTitle =this.cryptoService.decryptData(this.route.snapshot.paramMap.get('docTitle'));

    var title:string =   this.route.snapshot.paramMap.get('docTitle')||"";
    this.overlapsize = this.route.snapshot.paramMap.get('s')||2;
    if(this.overlapsize==1){
      this.overlapsize = 5;
    }else{
      this.overlapsize = 10
    }

    this.dynamicTitle =decodeURIComponent(title);
    this.getViewPageToken(stringVal);
    // // console.log("this.docId-->"+this.docId);
    }catch(err){
      alert("Data is missing.");
    }
  }

  // get safeUrl(){
  //   let id=this.docId;
  //   // id = this.cryptoService.decryptData(id);
  //   // docTitle = this.cryptoService.encryptData(docTitle);

  //   let payLoad = { docId:id};
  //   this.viewPageService.generateTokenForViewPage(payLoad).subscribe(res=>{
  //     // // console.log(JSON.stringify(res.statusCode));
  //     // alert(JSON.stringify(res.body.docToken));
  //     this.titleService.setTitle(this.dynamicTitle);
  //     this.titleService.setTitle(this.dynamicTitle);
  //     this.url = this.url+"&token="+res.body.docToken+"&email=undefined&orgid=undefined&canDownload=false";
  //     return  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  //   })

  //   return  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    
  // }

  getViewPageToken(id){
    // let id=this.docId;
    let docTitle = this.dynamicTitle;
    // id = this.cryptoService.decryptData(id);
    // docTitle = this.cryptoService.decryptData(docTitle);

    let payLoad = { docId:id};
    this.viewPageService.generateTokenForViewPage(payLoad).subscribe(res=>{
      // // console.log(JSON.stringify(res.statusCode));
      // alert(JSON.stringify(res.body.docToken));
      this.titleService.setTitle(this.dynamicTitle);
      this.titleService.setTitle(this.dynamicTitle);
      this.url = this.url+"&token="+res.body.docToken+"&email=undefined&orgid=undefined&canDownload=false";
      // // console.log("this.url", this.url);
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    })
  }
  
}
