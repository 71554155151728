import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OnedriveService {

  constructor(private httpClient:HttpClient) { }


  download(){
    alert("Alert from service!");
  }

  addtoonedriveVVMC(requestPayload:any){

    return  this.httpClient.post(ENVIRONMENT.ONEDRIVE.ADD_TO_ONEDRIVE_VVMC, requestPayload);

  }
  
  addtoonedriveVVQD(requestPayload:any){

    return  this.httpClient.post(ENVIRONMENT.ONEDRIVE.ADD_TO_ONEDRIVE_VVQD, requestPayload);

  }

  deleteDocumentFromOneDrive(requestPayload:any){
    return this.httpClient.post(ENVIRONMENT.ONEDRIVE.ONEDIVE_DELETE_URL,requestPayload);
  }
  
  async downloadVVMCDocumentToOneDrive(requestPayload:any){
    return await this.httpClient.post(ENVIRONMENT.ONEDRIVE.ADD_TO_ONEDRIVE_VVMC, requestPayload);
  }
  async downloadMultipleVVMCDocumentToOneDrive(userID:any,docId:any){
    return await this.httpClient.post(ENVIRONMENT.ONEDRIVE.ADD_TO_ONEDRIVE_VVMC, {userID,docId});
  }

  async downloadMultipleVVQDocumentToOneDrive(requestPayload:any){
    return await this.httpClient.post(ENVIRONMENT.ONEDRIVE.ADD_TO_ONEDRIVE_VVQD, requestPayload);
  }
  async downloadVVQDocumentToOneDrive(requestPayload:any){
    return await this.httpClient.post(ENVIRONMENT.ONEDRIVE.ADD_TO_ONEDRIVE_VVQD, requestPayload);
  }

  async getDownloadedDocumentsFromOneDrive(requestPayload:any){
    return await this.httpClient.post(ENVIRONMENT.ONEDRIVE.ONEDRIVE_GETALLDOCS, requestPayload)
  }

  async checkIfUserAlreadyRegistered(requestPayload:any){
   return await this.httpClient.post(ENVIRONMENT.ONEDRIVE.CHECK_IF_USER_REGISTERED, requestPayload);
  }
  

  startManualSync(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.ONEDRIVE.START_MANUAL_SYNC, requestPayload);
  }

  manualSyncProgress(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.ONEDRIVE.PROGRESS_BAR, requestPayload);
  }

}
