import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONFIG } from 'src/environments/environment';
import { SavedsearchService } from '../savedsearch.service';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
 id : any;
 user_id :any;
 pattern_name : any;
 isUserAlreadyUnsusbcribed:any = -1;
  constructor(private route: ActivatedRoute,private savedSearchService : SavedsearchService) { }
  async ngOnInit() {
    // Use snapshot to get query parameters
    const queryParams = this.route.snapshot.queryParams;
  
    // Access individual query parameters
    this.id = queryParams['id'];
    this.user_id = queryParams['user_id'];
    this.pattern_name = queryParams['patternName'];
  
    // Do something with the query parameters
    // console.log('param1:', this.id,this.user_id,this.pattern_name);

    var  payload = {
      "userId": this.user_id,
      "id":this.id,
      "subscription":1
}
  var resp:any =  await this.savedSearchService.unsubscribeSearch(payload).toPromise();
 // console.log("response", resp);

 if(resp.result == 0){
  this.isUserAlreadyUnsusbcribed = 0;
 }
 if(resp.result == 1){
  this.isUserAlreadyUnsusbcribed = 1;
 }

  }
  
  closeRenamePopup(){

    window.open(CONFIG.CONFIRMATION.REDIRECT_URL);
  }

  SavedSearchSubscribePopupShow : boolean = false;
  alreadySubscribed : boolean =false;

  unsubscription() {
 
    let payload = {
      subscription: 1,
      id: this.id,
      confirmation: true,
    };
    this.savedSearchService.unsubscribeSearch(payload).subscribe((searches) => {
      // this.savedSearches = searches.searchPatterns
      // console.log("this.savedSearches ---> ",searches.result);
      if(searches.result == 0)
      {
       this.isUserAlreadyUnsusbcribed = 2;
      }
      //     this.loadSearches();
      // this.loadAllSearchesServer();
      // if (this.dom.getElementById("subscriptionId") != null) {
      //   this.enableElement("subscriptionId");
      // }
    });
  }

}
