import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {

  constructor(private httpClient:HttpClient) { }

  getAllCollections(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.COLLECTIONS.GET_ALL_COLLECTIONS, requestPayload);
  }

  addDocToCollection(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.COLLECTIONS.ADD_TO_COLLECTIONS, requestPayload);
  }

  createCollection(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.COLLECTIONS.CREATE_COLLECTIONS, requestPayload);
  }

  renameDeleteCollection(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.COLLECTIONS.RENAME_DELETE_COLLECTIONS, requestPayload);
  }

  // settings 

  getAllSettings(): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SETTINGS.GET_ALL_SETTINGS_DATA, '');
  }

  updateSettings(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SETTINGS.UPDATE_USER_SETTINGS, requestPayload);
  }

  getUSerSettings(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SETTINGS.GET_USER_SETTINGS, requestPayload);
  }

  updateCollectionsUsed(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SETTINGS.UPDATE_COLLECTION_USED, requestPayload);
  }


}
