<!-- <app-header></app-header>
<div>
    <div id="popup1" class="overlay" (click)="closeRenamePopup()"></div>
    <div class="popup">
       
        <div class="content">
            <div class="title">Unsubscribe from Notifications</div>
            <div style="display: flex; flex-direction: column;">
                <div *ngIf="!SavedSearchSubscribePopupShow && !alreadySubscribed" class="input-section">
                    Do you really want to stop receiving notifications regarding <b>{{pattern_name}}</b> saved search?</div>
                    <div *ngIf="SavedSearchSubscribePopupShow" class="input-section">
                        You have successfully unsubscribed the {{pattern_name}} saved search.</div>
                        <div *ngIf="alreadySubscribed" class="input-section">
                            You have already unsubscribed the {{pattern_name}} saved search.</div>
                <div  class="btn-section">
                    <button   class="docFavCancleBtn" (click)="closeRenamePopup()" >Cancel</button>
                    <button *ngIf="!SavedSearchSubscribePopupShow" id="subscriptionId" (click)="subscription()" class="docFavSaveBtn" >Unsubscribe</button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer> -->

<app-header></app-header>
<div>
    <div id="popup1" class="overlay" (click)="closeRenamePopup()"></div>
    <div class="popup">
       
        <div class="content">
            <div class="title">Unsubscribe from Notifications</div>
            <div style="display: flex; flex-direction: column;">
                <div *ngIf="isUserAlreadyUnsusbcribed == 1">
                    Do you really want to stop receiving notifications regarding <b>{{pattern_name}}</b> saved search?</div>
                    <div  *ngIf="isUserAlreadyUnsusbcribed == 2" class="input-section">
                        You have successfully unsubscribed the <b>{{pattern_name}}</b> saved search.</div>
                        <div *ngIf="isUserAlreadyUnsusbcribed == 0" class="input-section">
                            You have already unsubscribed the <b>{{pattern_name}}</b> saved search.</div>
                <div  class="btn-section">
                    <button  *ngIf="isUserAlreadyUnsusbcribed == 1"  class="docFavCancleBtn" (click)="closeRenamePopup()" >Cancel</button>
                    <button  *ngIf="isUserAlreadyUnsusbcribed == 2 || isUserAlreadyUnsusbcribed == 0 "  class="docFavCancleBtn" (click)="closeRenamePopup()" >Close</button>
                    <button *ngIf="isUserAlreadyUnsusbcribed == 1"  id="subscriptionId" (click)="unsubscription()" class="docFavSaveBtn" >Unsubscribe</button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>