import { Component } from '@angular/core';
import { CookieServiceWrapper } from 'src/app/cookie-service-wrapper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  
  currentYear:any = new Date().getFullYear();
  email:any = "";

  constructor(private cookieService:CookieServiceWrapper){
           var currentYear = this.cookieService.get("currentYear");
           var email = this.cookieService.get("email"); 
           this.email = email;
           // console.log("email", email);
           // console.log("current year");
           if(currentYear){
            this.currentYear = currentYear;
           }

  }

}
