import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieServiceWrapper } from './cookie-service-wrapper.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class Customeventlogging {

  // constructor(private http: HttpClient) {}
  private _headers = new HttpHeaders;



  constructor(private httpClient:HttpClient,private http: HttpClient,private cookieService: CookieServiceWrapper, private cookie : CookieService) {
     this._headers = new HttpHeaders()
      .set('Content-Type','application/json',)
      .set('Authorization', `Bearer ${this.cookieService.get('st')}`
    )
   }

   logCustomEvent(eventType: string, customData?: Record<string, any>, eventValue?: string) {
    const event = {
      eventType,
      customData,
      eventValue,
      anonymous: false,
      clientId: this.cookie.get('coveo_visitorId'),
      language: "en-US"
    };
    let oid = this.cookieService.get('oid');
    const apiUrl = `https://${oid}.analytics.org.coveo.com/rest/ua/v15/analytics/custom`; // Adjust the URL as per your actual API endpoint
    
    this.httpClient.post(apiUrl, event, { headers: this._headers }).subscribe(
      (response) => {
        console.log('Custom event logged successfully:', response);
      },
      (error) => {
        console.error('Error logging custom event:', error);
      }
    );
  }
  


  logClickvent(payload: any) {
    let oid = this.cookieService.get('oid');
    const apiUrl = `https://${oid}.analytics.org.coveo.com/rest/ua/v15/analytics/click`; // Adjust the URL as per your actual API endpoint
  
    this.httpClient.post(apiUrl, payload, { headers: this._headers }).subscribe(
      (response) => {
        console.log('Click event logged successfully:', response);
      },
      (error) => {
        console.error('Error logging click event:', error);
      }
    );
  }
  
}


