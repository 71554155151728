<!-- <div class="custom-quick-view-overlay">
    <div class="custom-quick-view">
      <div class="image-container"> -->
        <div style="display: flex; gap:10px;" (click)="openQuickView();customEventsQuickView()">
          <div style="transform: translateY(12%);">
            <!-- <img [src]="imageUrl" alt="Image"> -->
            <span class="material-icons outlined" style="color: #00857c;">
              visibility
            </span>
          </div>
          <div class="botton-action-labels">Preview</div>
        </div>
        

        <!-- <button class="coveo-button" (click)="openModal()">Open Modal</button> -->
        <app-coveo-modal *ngIf="isModalOpen" [content]="content" (close)="closeModal()"></app-coveo-modal>

      <!-- </div>
      <div class="metadata">
      </div>
    </div>
  </div> -->

  <!-- <iframe srcdoc={{content}}></iframe> -->