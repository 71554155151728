import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResultsListDataService {
  requestBody:BehaviorSubject<any>;
   totalCount:BehaviorSubject<number>;
  constructor() {

     this.requestBody = new BehaviorSubject([]);
     this.totalCount = new BehaviorSubject(0);

   }

     setTotalCount(count){
            this.totalCount = count;
     }

     setRequestBody(results:any){
            this.requestBody = results;
     }

     getRequestBody(){
       this.requestBody;
     }
}
