<div class="coveo-modal-overlay" (click)="closeModal()"></div>
<div class="coveo-modal">
  <div class="coveo-modal-content">
    <!-- <h2>Modal Title</h2> -->
    <!-- <p>This is the modal content.</p> -->

    <iframe
      class="responsive-iframe"
      [src]="safeUrl"
      frameborder="0"
      style="overflow: hidden"
      height="100%"
      width="100%"
    ></iframe>

    <div class="coveo-modal-close" (click)="closeModal()">X</div>
  </div>
</div>
