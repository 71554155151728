import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private dataSubject = new Subject<string>();
  private collectionUpdated = new Subject<string>();
  private savedSearch = new Subject<any>();
  private collectionDetails = new Subject<any>();
  private generalRefresh = new Subject<any>();
  private deleteCollection = new Subject<any>();
  private renameCollection = new Subject<any>();
  private sharedCollectionSave = new Subject<any>();
  private clearAll = new Subject<any>();
  private queryPerformed = new Subject<any>();
  private loadCollectionsPartialSubject = new BehaviorSubject<boolean>(false);
  loadCollectionsPartial$: Observable<boolean> = this.loadCollectionsPartialSubject.asObservable();

  sendqueryPerformed(data: any) {
    this.queryPerformed.next(data);
  }

  getqueryPerformed(){
    return this.queryPerformed.asObservable();
  }


  sendclearAll(data: any) {
    this.clearAll.next(data);
  }

  getclearAll(){
    return this.clearAll.asObservable();
  }

  sendSharedCollectionSave(data: any) {
    this.sharedCollectionSave.next(data);
  }

  getSharedCollectionSave(){
    return this.sharedCollectionSave.asObservable();
  }

  sendRenameCollection(data: any) {
    this.renameCollection.next(data);
  }

  getRenameCollection(){
    return this.renameCollection.asObservable();
  }

  sendDeletedCollection(data: any) {
    this.deleteCollection.next(data);
  }

  getDeletedCollection(){
    return this.deleteCollection.asObservable();
  }

  sendCollectionData(data: any) {
    this.collectionDetails.next(data);
  }

  sendData(data: string) {
    this.dataSubject.next(data);
  }

  sendCollectionUpdate(data: string) {
    this.collectionUpdated.next(data);
  }

  sendSavedSearch(data: any) {
    this.savedSearch.next(data);
  }

  getData() {
    return this.dataSubject.asObservable();
  }

  getCollectionUpdate() {
    return this.collectionUpdated.asObservable();
  }

  getSavedSearch() {
    return this.savedSearch.asObservable();
  }

  getCollectionData() {
    return this.collectionDetails.asObservable();
  }




  setRefresh(data: any) {
    this.generalRefresh.next(data);
  }


  getRefresh() {
    return this.generalRefresh.asObservable();
  }

  setLoadCollectionsPartial(value: any): void {
    this.loadCollectionsPartialSubject.next(value);
  }
}
