import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { CONFIG } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
 email;
 isid;
 constructor(private httpClient:HttpClient, private router:Router){

 }
  async onSubmit(){
         // console.log("email", this.email);
    var payload = {
      email:this.email,
      isid:this.isid
    }
    // console.log("payload", payload);
   var response:any = await this.httpClient.post("https://z96trhrgie.execute-api.us-east-1.amazonaws.com/dev/getUserDetailsFromDB",payload).toPromise();
   // console.log("response", response);
   if(response.id){
    CONFIG.TEMP.USERID = response.id;
    
    // console.log("CONFIG.TEMP.USERID", CONFIG.TEMP.USERID);
    this.router.navigateByUrl("/results");

  }
  else{
    alert("Please enter correct details");
  }
  }
}
