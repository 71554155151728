import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ENVIRONMENT } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private key = ENVIRONMENT.privatekey; // Replace with your actual secret key

  encryptData(data: any): string {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
  }

  decryptData(data: string): any {
    const bytes = CryptoJS.AES.decrypt(data, this.key);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }

}
