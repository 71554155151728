import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-coveo-modal',
  templateUrl: './coveo-modal.component.html',
  styleUrls: ['./coveo-modal.component.css']
})
export class CoveoModalComponent {
  @Output() close = new EventEmitter<void>();
  @Input() content!:string;


  constructor(private sanitizer: DomSanitizer) {}

  public get safeUrl(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.content);
  }

  closeModal() {
    this.close.emit();
  }
}
