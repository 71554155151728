<!-- 
<div class="coveo-modal-overlay" (click)="closePopup()"></div>
<div  class="coveo-modal">
  <div class="coveo-modal-content">
    <div class="coveo-modal-close" (click)="closePopup()">X</div>

    <div class="popup-content" style="padding: 20px;
    height: 100%;
    width: 100%;" *ngIf="feature=='addToOneDrive'">
    <div class="heading">Download to OneDrive</div>
    <div class="content centerItem">Would you like to download the selected document(s) to your OneDrive?</div>
    <div style="text-align: center;"><button class="btn" type="button" (click)="download()">Download</button>
      <button class="btn" type="button" (click)="closePopup()">Close</button>
    </div>
    </div>


    <div class="popup-content centerItem" *ngIf="feature=='checkboxLimitSelection'">
    <div class="heading" >You may only select up to 10 at once.</div>
    <div class="content centerItem">Maximum number of selections reached</div>
    <div style="text-align: center;">
      <button class="btn" type="button" (click)="closePopup()">Close</button>
    </div>
    </div>
</div>
 -->

 <!-- <div class="coveo-modal-overlay" (click)="closePopup()"></div>
 <div [ngClass]="{'coveo-modal': !maximumFlag, 'coveo-modal-maximum-selection': maximumFlag}">
     <div *ngIf="feature != 'collections' && feature != 'addCollections' " class="coveo-modal-content">
         <div class="coveo-modal-close" (click)="closePopup()">X</div>

<div class="coveo-modal-overlay" (click)="closePopup()"></div>
<div class="coveo-modal">
  <div class="coveo-modal-content">
    <div class="coveo-modal-close" (click)="closePopup()">X</div>


    <div class="popup-content" style="padding: 20px;
    height: 100%;
    width: 100%;">
      <div class="title">{{title}}</div>

      <div style="font-size: 100%;margin-top:1rem; padding-right: 4%;padding-bottom: 4%;">{{body}}</div>
      <div *ngIf="(feature == 'addToOneDrive' || 'removeFromOneDrive') &&  closeBtn == false" style="font-size: 100%;margin-top:1rem; padding-right: 4%;padding-bottom: 4%;">{{failedDocs}}</div>
      <div *ngIf="feature == 'addToOneDrive' &&  closeBtn == false"
        style="bottom:128px; left:0px; right:0px; text-align:center; position:absolute"> <button (click)="closePopup()"
          class="docFavCancleBtn">Close</button><button class="docFavSaveBtn" style="width:7rem"
          (click)="addToOneDrive()">Download</button></div> 
          
          <br>

      <div *ngIf="feature == 'removeFromOneDrive' && closeBtn == false"
        style="bottom:128px; left:0px; right:0px; text-align:center; position:absolute"> <button (click)="closePopup()"
          class="docFavCancleBtn">No</button><button class="docFavSaveBtn" style="width:7rem"
          (click)="removeFromOneDrive()">Yes</button></div>

          <div *ngIf="feature == 'removeMultipleFromOneDrive' && closeBtn == false"
        style="bottom:128px; left:0px; right:0px; text-align:center; position:absolute"> <button (click)="closePopup()"
          class="docFavCancleBtn">No</button><button class="docFavSaveBtn" style="width:7rem"
          (click)="removeMultipleDocsFromOneDrive()">Yes</button></div> 

          
          <br>
          
      <div *ngIf="closeBtn" style="bottom:128px; left:0px; right:0px; text-align:center; position:absolute"> <button
          (click)="closePopup()" [ngClass]="{'docFavCancleBtn': !maximumFlag, 'docFavCancleBtn-maximum-selection': maximumFlag}" >Close</button></div> <br>
      <span *ngIf="progress" style="color:red">{{progressMessage}}</span>
      <div style="font-size: 12px; padding-top: 35px;">
        <div><span style="font-weight: bold;">{{oneDriveInfoMessage}}</span>
        </div>
      </div>
    </div>

  </div>    
     
         <div class="popup-content" style="padding: 20px; height: 100%; width: 100%;">
             <div class="title">{{title}}</div>
 
             <div style="font-size: 100%; margin-top: 1rem; padding-right: 4%; padding-bottom: 4%;">{{body}}</div>
             <div *ngIf="(feature == 'addToOneDrive' || 'removeFromOneDrive') &&  closeBtn == false" style="font-size: 100%; margin-top: 1rem; padding-right: 4%; padding-bottom: 4%;">{{failedDocs}}</div>
             <div *ngIf="feature == 'addToOneDrive' &&  closeBtn == false" style="bottom: 128px; left: 0px; right: 0px; text-align: center; position: absolute;">
                 <button (click)="closePopup()" class="docFavCancleBtn">Close</button>
                 <button class="docFavSaveBtn" style="width: 7rem;" (click)="addToOneDrive()">Download</button>
             </div>
 
             <br />
 
             <div *ngIf="feature == 'removeFromOneDrive' && closeBtn == false" style="bottom: 128px; left: 0px; right: 0px; text-align: center; position: absolute;">
                 <button (click)="closePopup()" class="docFavCancleBtn">No</button>
                 <button class="docFavSaveBtn" style="width: 7rem;" (click)="removeFromOneDrive()">Yes</button>
             </div>
 
             <br />
 
             <div *ngIf="closeBtn" style="bottom: 128px; left: 0px; right: 0px; text-align: center; position: absolute;">
                 <button (click)="closePopup()" [ngClass]="{'docFavCancleBtn': !maximumFlag, 'docFavCancleBtn-maximum-selection': maximumFlag}">Close</button>
             </div>
             <br />
             <span *ngIf="progress" style="color: red;">{{progressMessage}}</span>
             <div *ngIf="(feature == 'removeFromOneDrive' || 'addToOneDrive')  && closeBtn == false" style="font-size: 12px; padding-top: 35px;">
                 <div><span style="font-weight: bold;">{{oneDriveInfoMessage}}</span></div>
             </div>
         </div>
     </div>
     <div *ngIf="feature == 'addCollections' && collectionAddFlag" style="overflow-x:hidden;overflow-y:hidden" class="coveo-modal-content">
      <div class="popup-content" style="padding: 20px; height: 100%; width: 100%;">
         <div class="title">Save the document to the following collection(s)</div>
           <div style="height:175px !important;overflow-y:scroll;width:95%">
            <div style="font-size: 100%; margin-top: 1rem; padding-bottom: 1%;" *ngFor="let collection of collectionslist">

            <div><input type="checkbox" [checked]="isDocPartOfCollection(collection)" [value]="collection.id" (change)="onCollectionCheckboxChange($event,collection)" />
              <span style="color: blue;text-decoration:underline">
                {{collection.name}}
              </span>
            </div>
           </div> 

         </div>
         <div class="collectionsBtn">
          <span (click)="addremoveCollections()" style="margin:10px">Add Collection</span>
          <span (click)="shareDocument()">Share Collection</span>
         </div>
         <div class="renameBtn">
          <button style="color:teal !important"  class="docFavCancleBtn" (click)="closeRenamePopup()">Cancel</button>
          <button class="docFavSaveBtn" (click)="saveCollectionChanges()">Save</button>
        </div> 
     </div>
 </div>
    
     <div *ngIf="feature == 'addCollections' && collectionViewFlag" style="overflow-x:hidden;overflow-y:hidden" class="coveo-modal-content">
      <div class="popup-content" style="padding: 20px; height: 100%; width: 100%;">
         <div class="title">My Collections</div>
           <div style="height:200px !important;overflow-y:scroll;width:95%">
            <div style="font-size: 100%; margin-top: 1rem; padding-right: 4%; padding-bottom: 1%;" *ngFor="let collection of collectionslist">

            <div><input type="checkbox" [checked]="isDocPartOfCollection(collection)" [value]="collection.id" (change)="onCollectionCheckboxChange($event,collection)" />
              <span style="color: blue;text-decoration:underline">
                {{collection.name}}
              </span>
              
             <span class="colSpan">
              <span (click)="rename(collection)" class="colSpace">Rename</span>
             <span (click)="delete(collection)" class="colSpace">Delete</span>
             <span (click)="share(collection)" class="colSpace">Share</span>
            </span>
            </div>
           </div> 

         </div>
     </div>
 </div>
 

 <div *ngIf="renameFlag == true" class="popup-overlay">
  <div class="popup-container">
    <h2>Rename Collection</h2>
    <div class="popupBody">
      <label>Name</label>
      <input class="renameInput" type="text" (change)="renameTextValue($event)" >
    </div>
    <div class="renameBtn">
      <button style="color:teal !important"  class="docFavCancleBtn" (click)="closeRenamePopup()">Cancel</button>
      <button class="docFavSaveBtn" (click)="renameUpdate()">Rename</button>
    </div>    
      
    </div>
  </div>

  <div *ngIf="newnameFlag == true" class="popup-overlay">
    <div class="popup-container">
      <h2>Save Collection</h2>
      <div class="popupBody">
        <label>Name</label>
        <input class="renameInput" type="text" (change)="newnameTextValue($event)" >
      </div>
      <div class="renameBtn">
        <button style="color:teal !important"  class="docFavCancleBtn" (click)="closeNewCollection()">Cancel</button>
        <button class="docFavSaveBtn" (click)="addCollections()">Save</button>
      </div>    
        
      </div>
    </div>


  <div *ngIf="deleteFlag == true" class="popup-overlay">
    <div class="popup-container">
      <h2>Delete Confirmation</h2>
      <div class="popupBody">
       <span>Do you want to delete the collection named, {{selecedNameList}}?</span>
      </div>
      <div class="renameBtn">
        <button style="color:teal !important"  class="docFavCancleBtn" (click)="closeDeletePopup()">Cancel</button>
        <button class="docFavSaveBtn" (click)="deleteCollection()">Delete</button>
      </div>    
        
      </div>
    </div> -->



  <!-- <div *ngIf="feature=='manualSync'">
    <div id="popup1" class="overlay" (click)="closePopup()">
        <div class="popup">
            <h2>{{title}}</h2>
            <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a>
            <div class="content" style="color: black;">
                {{body}}
            </div>
        </div>
    </div>
</div> -->


<div *ngIf="feature=='manualSync'">
  <div id="popup1" class="overlay" (click)="closePopup()">
      <div class="popup">
          <!-- <h2>{{title}}</h2> -->
          <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
          <div class="content" style="color: black;">
            <div style="display: flex; flex-direction: row;">
              <!-- <div style="flex-basis: 10%;"><div class="oneDriveInfoQuestionIcon">&check;</div></div> -->
              <div style="flex-basis: 100%;">
                  <div class="popup-title">Sync to OneDrive</div>
                  <div class="popup-question"><div style="line-height: 19px;">{{body}}</div></div>
                  <div class="popup-action-section-single"><span></span><button class="docFavSaveBtn teal-button" (click)="closePopup()">Close</button></div>
              </div>
            </div>
              
          </div>
      </div>
  </div>
</div>

<div *ngIf="feature=='mycollectionsmessage'">
  <div id="popup2" class="overlay" (click)="feature='none'; closeMyCollectionEmptyPopup()">
      <div class="popup">
          <!-- <h2>{{title}}</h2> -->
          <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
          <div class="content" style="color: black;">
            <div style="display: flex; flex-direction: row;">
              <!-- <div style="flex-basis: 10%;"><div class="oneDriveInfoQuestionIcon">&check;</div></div> -->
              <div style="flex-basis: 100%;">
                  <div class="popup-title">{{title}}</div>
                  <div class="popup-question"><div>{{body}}</div></div>
                  <div class="popup-action-section-single"><button class="docFavSaveBtn teal-button" (click)="closeMyCollectionEmptyPopup()">Close</button></div>
              </div>
            </div>
              
          </div>
      </div>
  </div>
</div>


<div *ngIf="feature=='mydriveempty'">
  <div id="popup2" class="overlay" (click)="feature='none';closeMydriveEmpty();">
      <div class="popup">
          <!-- <h2>{{title}}</h2> -->
          <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
          <div class="content" style="color: black;">
            <div style="display: flex; flex-direction: row;">
              <!-- <div style="flex-basis: 10%;"><div class="oneDriveInfoQuestionIcon">&check;</div></div> -->
              <div style="flex-basis: 100%;">
                  <div class="popup-title">{{title}}</div>
                  <div class="popup-question"><div>{{body}}</div></div>
                  <div class="popup-action-section-single"><button class="docFavSaveBtn teal-button" (click)="feature='none'; closeMydriveEmpty();">Close</button></div>
              </div>
            </div>
              
          </div>
      </div>
  </div>
</div>
