import {Component, Input} from '@angular/core';
import {Result} from '@coveo/headless';


@Component({
  selector: 'app-result-button',
  templateUrl: './result-button.component.html',
  styleUrls: ['./result-button.component.css']
})
export class ResultButtonComponent {
  @Input() public result!: Result;
  @Input() public clickCallback!: Function;
  @Input() public buttonText: String = '';

  public constructor() {
  }

}
