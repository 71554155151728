import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit{
//  errorData = {
//   "message" : "Your%20access%20to%20Search%20Navigator%20has%20been%20removed%20due%20to%20your%20recent%20organization%20move.%20If%20this%20is%20an%20error%2C%20please%20submit%20a%20help%20desk%20ticket%20to%20restore%20access."
//  }
 errorData:any = "";
   constructor(private router:Router){
      this.errorData = this.router.getCurrentNavigation()?.extras?.state;

   }
   ngOnInit(): void {
    
       // console.log("this.errorData", this.errorData.message);
        //document.getElementById('error').innerHTML="Error:"+error;
         this.errorData.message = decodeURIComponent(this.errorData.message);
        // this.errorData.message =   this.validateInput( this.errorData.message);
        // this.errorData.message =   this.sanitizeInput( this.errorData.message);
        // this.errorData.message =   this.escapeRegExp( this.errorData.message);
        // this.errorData.message =   this.htmlEncode( this.errorData.message);
       
   }
   htmlEncode(n) {
    return String(n).replace(/[^\w., ]/gi, function (n) {
        return "&#" + n.charCodeAt(0) + ";";
    });
}
 validateInput(n) {
    return n.match(/^[a-zA-Z0-9 .,?!@#$%^&*()_+-=;:'"|\\/]*$/) ? n : "Invalid input";
}
 escapeRegExp(n) {
    return n.replace(/[^a-zA-Z0-9 ]/g, "");
}
sanitizeInput(n) {
  return n.replace(/[^a-zA-Z0-9 .,?!@#$%^&*()_+-=;:'"|\\/]/g, "");
}


   

}
