import { Injectable, OnInit } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieServiceWrapper } from './cookie-service-wrapper.service';
import { NON_PROXY_API } from 'src/environments/environment';
import { ConfigService } from './config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {



  constructor( private cookieService: CookieServiceWrapper, private configService : ConfigService) {

 
    
  }

  

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
 
    if(request.url.includes("config.json")){
      return next.handle(request);
    }
    
    let proxyBase64 = this.cookieService.get("proxyBase64");
    let proxyxmerckapi = this.cookieService.get("proxyxmerckapi");
    
     
     const  modifiedRequest = request.clone({
      setHeaders : {
        "Authorization": proxyBase64,
         "x-merck-apikey": proxyxmerckapi
      }

     
     })
      if(this.checkIfNotAProxyAPI(request)){
        return next.handle(request);
      }
    return next.handle(modifiedRequest);
  }

     checkIfNotAProxyAPI(request: HttpRequest<unknown>){
         
    return NON_PROXY_API.some((url) => request.url.includes(url))
     }
}
