











  <div class="new-header" style="line-height: 50px;">
    <div class="new-header-block" style="display: grid;">

      <div style="cursor: pointer;">
        <div style="display: inline-block;height: 50px;line-height: 45px;"><img class="logo-img" style="vertical-align:top; margin-top:-4px" src="../../assets/merck-assets/MerckIconWhite.png"/></div>
        <div style="display: inline-block;height: 50px;vertical-align: middle;line-height: 45px; margin-top:5px">
          <span class="logo-label">Search Navigator</span>
        </div>
      </div>
     

</div>

<div class="pageSubTitle">
    <h1 
    style="font-size: 20px; font-weight: 400;"
>Scheduled Maintenance</h1>
</div>

 <div style="color:black">
    
       
    
     <img height="200px"  style="display:block;margin-left: auto;margin-right: auto; margin-top:70px" src="../../assets/merck-assets/MaintenanceLogo.png">
    
     

     
     <br><span style="text-align: center; display: block; font-weight: 700; line-height: 1.1;">Come back soon.</span>
     <span style="text-align: center; display: block; font-weight: 400; line-height: 1.4">Search Navigator is currently down for scheduled maintenance.</span>
     <span style="text-align: center; display: block; font-weight: 400; line-height:  1.1;">Please come back shortly to access Search Navigator.</span>
     
                            
 </div>

</div>
    
